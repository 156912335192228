import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { IoIosClose } from 'react-icons/io';
import { numberWithCommas } from '../../../utils/tools';
import TreasuryContext from '../../../context/private/treasuryContext';
import { Link } from 'react-router-dom';

type TModal = {
  onChange?: (e: any) => void;
  onClick?: () => void;
  min?: number;
  max?: number | string;
  image?: string;
  title?: string;
  isOpen?: boolean;
  setIsOpen?: (e: boolean) => void;
  value?: any;
  setValue?: any;
  balance?: any;
  balancePure?: any;
  islandId?: any;
};

const ModalCalimAndCompound: React.FC<TModal> = ({
  onChange,
  onClick,
  min,
  max,
  max: mainBalance,
  image,
  title,
  isOpen,
  setIsOpen,
  // value,
  // setValue,
  balance,
  balancePure,
  islandId
}: any) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [value, setValue] = useState<any>({ amount: 0 });

  const [selectedRange, setSelectedRange] = useState<string | null>('');

  const [claimAmount, setClaimAmount] = useState<any>('');
  const [compoundAmount, setCompoundAmount] = useState<any>('');

  const {
    islandsDetailData,
    ckeditorClaimWindowModal,
    loadCkEditorByID,
    claimAndCompoundHandle
  } = useContext(TreasuryContext);

  useEffect(() => {
    loadCkEditorByID(9);
  }, []);
  const [isTermsCheck, setTermsCheck] = useState('');

  const { currentIslandId } = useContext(TreasuryContext);

  const handleRangeSelection = (range: string) => {
    setSelectedRange(range);
    const [claimRatio, compoundRatio] = range.split('/').map(Number);
    const totalRatio = claimRatio + compoundRatio;

    // if (claimAmount > 0 || compoundAmount > 0) {
    //   const newClaimAmount = ((claimAmount + compoundAmount) * claimRatio) / totalRatio;
    //   const newCompoundAmount = ((claimAmount + compoundAmount) * compoundRatio) / totalRatio;
    //   setClaimAmount(Math.round(newClaimAmount * 100) / 100);
    //   setCompoundAmount(Math.round(newCompoundAmount * 100) / 100);
    // } else {

    const newClaimAmount = (Number(mainBalance) * claimRatio) / totalRatio;
    const newCompoundAmount =
      (Number(mainBalance) * compoundRatio) / totalRatio;
    setClaimAmount(+String(newClaimAmount * 100).split('.')[0] / 100);
    setCompoundAmount(+String(newCompoundAmount * 100).split('.')[0] / 100);
    // }
  };

  const handleClaimChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newClaimAmount = parseFloat(e.target.value);
    setClaimAmount(isNaN(newClaimAmount) ? 0 : newClaimAmount);

    if (e.target.value.split('.')?.[1]?.length >= 2) {
      setClaimAmount(
        isNaN(newClaimAmount)
          ? 0
          : +String(newClaimAmount * 100).split('.')[0] / 100
      );
    } else {
      setClaimAmount(isNaN(newClaimAmount) ? 0 : newClaimAmount);
    }

    if (selectedRange) {
      const [claimRatio, compoundRatio] = selectedRange.split('/').map(Number);
      const newCompoundAmount = (newClaimAmount * compoundRatio) / claimRatio;
      setCompoundAmount(+String(newCompoundAmount * 100).split('.')[0] / 100);
    } else {
      const newCompoundAmount = Number(mainBalance) - newClaimAmount;
      setCompoundAmount(+String(newCompoundAmount * 100).split('.')[0] / 100);
    }
  };

  const handleCompoundChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCompoundAmount = parseFloat(e.target.value);

    if (e.target.value.split('.')?.[1]?.length >= 2) {
      setCompoundAmount(
        isNaN(newCompoundAmount)
          ? 0
          : +String(newCompoundAmount * 100).split('.')[0] / 100
      );
    } else {
      setCompoundAmount(isNaN(newCompoundAmount) ? 0 : newCompoundAmount);
    }

    if (selectedRange) {
      const [claimRatio, compoundRatio] = selectedRange.split('/').map(Number);
      const newClaimAmount = (newCompoundAmount * claimRatio) / compoundRatio;
      setClaimAmount(+String(newClaimAmount * 100).split('.')[0] / 100);
    } else {
      const newClaimAmount = Number(mainBalance) - newCompoundAmount;
      setClaimAmount(+String(newClaimAmount * 100).split('.')[0] / 100);
    }
  };

  const setClaimChange = (value) => {
    const newClaimAmount = parseFloat(value);

    if (value && String(value)?.split('.')?.[1]?.length >= 2) {
      setClaimAmount(
        isNaN(newClaimAmount)
          ? 0
          : +String(newClaimAmount * 100).split('.')[0] / 100
      );
    } else {
      setClaimAmount(isNaN(newClaimAmount) ? 0 : newClaimAmount);
    }

    // if (selectedRange) {
    //   const [claimRatio, compoundRatio] = selectedRange.split('/').map(Number);
    //   const newCompoundAmount = (newClaimAmount * compoundRatio) / claimRatio;
    //   setCompoundAmount(+String(newCompoundAmount * 100).split('.')[0] / 100);
    // } else {
    //   const newCompoundAmount = Number(mainBalance) - newClaimAmount;
    //   setCompoundAmount(+String(newCompoundAmount * 100).split('.')[0] / 100);
    // }
  };

  const setCompoundChange = (value) => {
    const newCompoundAmount = parseFloat(value);

    if (value && String(value)?.split('.')?.[1]?.length >= 2) {
      setCompoundAmount(
        isNaN(newCompoundAmount)
          ? 0
          : +String(newCompoundAmount * 100).split('.')[0] / 100
      );
    } else {
      setCompoundAmount(isNaN(newCompoundAmount) ? 0 : newCompoundAmount);
    }

    // if (selectedRange) {
    //   const [claimRatio, compoundRatio] = selectedRange.split('/').map(Number);
    //   const newClaimAmount = (newCompoundAmount * claimRatio) / compoundRatio;
    //   setClaimAmount(+String(newClaimAmount * 100).split('.')[0] / 100);
    // } else {
    //   const newClaimAmount = Number(mainBalance) - newCompoundAmount;
    //   setClaimAmount(+String(newClaimAmount * 100).split('.')[0] / 100);
    // }
  };

  return (
    <>
      <div
        onClick={() => setIsOpen(false)}
        className={` bg-black bg-opacity-[0.65] inset-0 z-[150] fixed w-full h-full transition duration-300 backdrop-blur-[3px] ${
          isOpen ? 'visible opacity-100' : 'hidden invisible opacity-0'
        }`}
      ></div>
      <div
        className={`mt-40 bg-white absolute left-1/2 w-full p-4 lg:p-8 rounded-3xl max-w-[1037px] h-fit transition duration-300 -translate-x-1/2 z-[155] ${
          isOpen ? 'visible opacity-100' : 'invisible opacity-0'
        }`}
      >
        <div className="flex justify-between items-center mb-5 border-b border-gray-400 pb-5">
          <span className="font-bold text-lg lg:text-[22px] ">{title}</span>
          <div
            onClick={() => setIsOpen(false)}
            className="p-1 cursor-pointer rounded-full bg-[#e9f1f599] hover:rotate-360 transition"
          >
            <IoIosClose className="text-5xl opacity-50" />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-10 w-full justify-between">
          <div className="md:col-span-7 gap-4 flex flex-col">
            <div className="flex justify-between w-full items-center">
              {balance ? balance : null}
              {max ? (
                <div
                  className="flex gap-x-2 items-center cursor-pointer"
                  onClick={() => {
                    const maxValue = Math.floor(max * 100) / 100;
                    setValue({ ...value, amount: maxValue });
                    if (selectedRange) {
                      const [claimRatio, compoundRatio] = selectedRange
                        .split('/')
                        .map(Number);
                      const totalRatio = claimRatio + compoundRatio;
                      setClaimAmount(
                        Number(((maxValue * claimRatio) / totalRatio) * 100) /
                          100
                      );
                      setCompoundAmount(
                        Number(
                          ((maxValue * compoundRatio) / totalRatio) * 100
                        ) / 100
                      );
                    }
                  }}
                >
                  <span className="text-2xl">Available:</span>
                  <span>${numberWithCommas(max)}</span>
                </div>
              ) : null}
            </div>

            <div className="flex flex-col gap-2">
              <label className="appf-type-label text-[#00000099] text-[14px] font-normal leading-normal">
                Claim Amount:
              </label>

              <input
                type="number"
                step="0.01"
                className="bg-[#f5f5f5] border border-[#eee] h-[52px] px-2 lg:px-4 rounded-xl placeholder:text-gray-500"
                min={0}
                max={max}
                onChange={handleClaimChange}
                onFocus={() => setSelectedRange('')}
                value={claimAmount > 0 ? claimAmount : ''}
                placeholder="Enter an amount"
              />
              <div className="flex justify-between w-full items-center">
                <span></span>
                {max ? (
                  <div
                    className="flex gap-x-2 items-center cursor-pointer"
                    onClick={() => {
                      setSelectedRange('');
                      setClaimAmount('');
                      setCompoundAmount(0);
                      setClaimChange(max);
                    }}
                  >
                    <span className="text-2xl">Max:</span>
                    <span>${numberWithCommas(max)}</span>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <label className="appf-type-label text-[#00000099] text-[14px] font-normal leading-normal">
                Compound Amount:
              </label>

              <input
                type="number"
                step="0.01"
                className="bg-[#f5f5f5] border border-[#eee] h-[52px] px-2 lg:px-4 rounded-xl placeholder:text-gray-500"
                min={0}
                max={max}
                onChange={handleCompoundChange}
                onFocus={() => setSelectedRange('')}
                value={compoundAmount > 0 ? compoundAmount : ''}
                placeholder="Enter an amount"
              />

              <div className="flex justify-between w-full items-center">
                <span></span>
                {max ? (
                  <div
                    className="flex gap-x-2 items-center cursor-pointer"
                    onClick={() => {
                      setSelectedRange('');
                      setClaimAmount(0);
                      setCompoundAmount('');
                      setCompoundChange(max);
                    }}
                  >
                    <span className="text-2xl">Max:</span>
                    <span>${numberWithCommas(max)}</span>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="flex justify-between mb-4 bg-[#f5f5f5] p-2 py-3 rounded-2xl">
              {['70/30', '50/50', '30/70'].map((option) => (
                <button
                  key={option}
                  className={`flex-1 py-2 px-4 rounded-lg border-2 ${
                    selectedRange === option
                      ? 'bg-[#153458] text-white border-[#0d2036]'
                      : 'border-gray-300 hover:border-[#0d2036]'
                  } focus:outline-none focus:ring-2 focus:ring-[#0d2036] focus:border-transparent transition-all duration-200 ease-in-out ${
                    option !== '50/50' ? 'mx-2' : ''
                  }`}
                  onClick={() => handleRangeSelection(option)}
                >
                  <span className="block text-center font-semibold">
                    {option}
                  </span>
                </button>
              ))}
            </div>

            {/* <div className="flex justify-between w-full items-center">
              {min ? (
                <div
                  className="flex gap-x-2 items-center cursor-pointer"
                  onClick={() => {
                    const minValue = Math.floor(min * 100) / 100;
                    setValue({ ...value, amount: minValue });
                    if (selectedRange) {
                      const [claimRatio, compoundRatio] = selectedRange.split('/').map(Number);
                      const totalRatio = claimRatio + compoundRatio;
                      setClaimAmount(Math.round((minValue * claimRatio) / totalRatio * 100) / 100);
                      setCompoundAmount(Math.round((minValue * compoundRatio) / totalRatio * 100) / 100);
                    }
                  }}
                >
                  <span className="text-2xl">Min:</span>
                  <span>${numberWithCommas(min)}</span>
                </div>
              ) : null}
              {max ? (
                <div
                  className="flex gap-x-2 items-center cursor-pointer"
                  onClick={() => {
                    const maxValue = Math.floor(max * 100) / 100;
                    setValue({ ...value, amount: maxValue });
                    if (selectedRange) {
                      const [claimRatio, compoundRatio] = selectedRange.split('/').map(Number);
                      const totalRatio = claimRatio + compoundRatio;
                      setClaimAmount(Math.round((maxValue * claimRatio) / totalRatio * 100) / 100);
                      setCompoundAmount(Math.round((maxValue * compoundRatio) / totalRatio * 100) / 100);
                    }
                  }}
                >
                  <span className="text-2xl">Max:</span>
                  <span>${numberWithCommas(max)}</span>
                </div>
              ) : null}
            </div> */}

            <div className="mod-inside-input h-full max-h-[220px] overflow-y-auto">
              <span
                className="text-wrapper-6"
                dangerouslySetInnerHTML={{
                  __html: islandsDetailData?.claim_description_view
                    ? islandsDetailData?.claim_description
                    : ckeditorClaimWindowModal
                }}
              ></span>
            </div>
          </div>
          <div className="md:col-span-5">
            <div className="flex flex-col gap-y-2">
              <img src={image} className="w-full h-60 object-contain" />

              <div className="flex flex-col gap-y-2 text-center">
                <div className="text-3xl">Your Requested Amount</div>
                <div className="text-4xl text-[#121212]">
                  $
                  {claimAmount || compoundAmount
                    ? numberWithCommas(
                        Number(claimAmount) + Number(compoundAmount)
                      )
                    : 0}
                </div>
              </div>

              <div className="flex flex-col mb-4 gap-y-2 rounded-2xl border-dashed border-[2px] border-[#EDEDED] p-4">
                <div className="flex justify-between items-center">
                  <span className="text-gray-500">Payment Method: </span>
                  <span className="text-[#121212]">Claim & Compound</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-500">
                    Requested Amount for Claim:{' '}
                  </span>
                  <span className="text-[#121212]">
                    ${claimAmount > 0 ? numberWithCommas(claimAmount) : 0}
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-500">
                    Requested Amount for Compound:{' '}
                  </span>
                  <span className="text-[#121212]">
                    ${compoundAmount > 0 ? numberWithCommas(compoundAmount) : 0}
                  </span>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="382"
                  height="2"
                  viewBox="0 0 382 2"
                  fill="none"
                >
                  <line
                    x1="0.310547"
                    y1="1.1673"
                    x2="381.311"
                    y2="1.1673"
                    stroke="#EDEDED"
                    stroke-width="1.66539"
                    stroke-dasharray="6.66 6.66"
                  />
                </svg>

                <div className="flex justify-between items-center">
                  <span className="text-gray-500">Claim Fee: </span>
                  <span className="text-[#121212]">
                    $
                    {claimAmount
                      ? numberWithCommas(
                          claimAmount * (islandsDetailData?.withdraw_fee / 100)
                        )
                      : 0}
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-500">Compound Fee: </span>

                  <span className="text-[#121212]">
                    $
                    {compoundAmount > 0
                      ? numberWithCommas(
                          compoundAmount *
                            (islandsDetailData?.compound_fee / 100)
                        )
                      : 0}
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-500">
                    Your Loan Allocation will be:{' '}
                  </span>
                  <span className="text-[#121212]">
                    {islandsDetailData?.balance
                      ? compoundAmount > 0
                        ? `$${numberWithCommas(
                            Number(islandsDetailData?.balance) +
                              Number(
                                compoundAmount -
                                  compoundAmount *
                                    ((islandsDetailData?.compound_fee || 0) /
                                      100)
                              ),
                            2
                          )}`
                        : `$${numberWithCommas(islandsDetailData?.balance, 2)}`
                      : '$0'}
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-500">
                    Your BOS Balance will be:{' '}
                  </span>
                  <span className="text-[#121212]">
                    {balancePure && compoundAmount > 0 && compoundAmount
                      ? compoundAmount
                        ? `$${numberWithCommas(
                            Number(balancePure) +
                              Number(
                                compoundAmount -
                                  compoundAmount *
                                    ((islandsDetailData?.compound_fee || 0) /
                                      100)
                              ),
                            2
                          )}`
                        : `$${numberWithCommas(balancePure, 2)}`
                      : '$0'}
                  </span>
                </div>
              </div>
              {/* <input
                type="number"
                className="bg-[#f5f5f5] border border-[#eee] h-[52px] px-2 lg:px-4 rounded-xl placeholder:text-gray-500"
                min={0}
                max={max}
                onChange={onChange}
                value={value?.amount}
                placeholder="Enter an amount"
              /> */}

              <label
                htmlFor="terms-checkbox"
                className="flex gap-2 align-center cursor-pointer"
              >
                <input
                  className="checkbox-size"
                  type="checkbox"
                  id="terms-checkbox"
                  name="terms-checkbox"
                  onChange={(e) =>
                    setTermsCheck(e.target.checked ? 'checked' : '')
                  }
                />
                <p className="text-gray-500 text-xl font-sans">
                  I have reviewed and agree to the{' '}
                  <Link
                    to={`/island-page?id=${currentIslandId}&tab=conditions`}
                    target="_blank"
                  >
                    Island Conditions.
                  </Link>
                </p>
              </label>

              <button
                onClick={(e) => {
                  if (isTermsCheck) {
                    if (
                      Number(compoundAmount) > max ||
                      Number(claimAmount) > max ||
                      Number(claimAmount) + Number(compoundAmount) > max
                    ) {
                      toast.error(
                        'The maximum amount is $' +
                          +String(max * 100).split('.')[0] / 100
                      );
                    } else {
                      claimAndCompoundHandle(
                        claimAmount,
                        compoundAmount,
                        islandId,
                        setClaimAmount,
                        setCompoundAmount
                      );
                    }
                  }
                }}
                disabled={
                  Number(compoundAmount) > max ||
                  Number(claimAmount) > max ||
                  Number(claimAmount) + Number(compoundAmount) > max ||
                  !isTermsCheck
                }
                className={
                  Number(compoundAmount) > max ||
                  Number(claimAmount) > max ||
                  Number(claimAmount) + Number(compoundAmount) > max ||
                  !isTermsCheck
                    ? 'bg-[#153458] w-full h-[54px] text-2xl font-extrabold text-white md:text-[24px] rounded-2xl mt-10 opacity-5'
                    : 'bg-[#153458] w-full h-[54px] text-2xl font-extrabold text-white md:text-[24px] rounded-2xl mt-10'
                }
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalCalimAndCompound;
