import React, { useContext, useEffect, useState } from 'react';
import './style.css';
import { useWindowWidth } from '../breakpoint';
import WalletContext from '../../../context/private/walletContext';
import PrivateContext from '../../../context/private/privateContext';
import {
  isMatching,
  numberWithCommas,
  timeToYYYYMMDD
} from '../../../utils/tools';
import dictionary from '../../../locals/my-wallet.json';
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { QRCodeSVG } from 'qrcode.react';
import DickPickerInputCryptoTransfer from '../../MyWallet/modal/date-picker-crypto-transfer-date';
import info from '../assets/info.svg';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { Flex } from '../../../styles/Styles';
import { ModalMethods, SuccessModal } from '../Success';
import DickPickerInputWireTransfer from '../../MyWallet/modal/date-picker-wire-transfer-date';
import DickPickerInputWireExp from '../../MyWallet/modal/date-picker-wire-expected-date';
import infoIcon from '../assets/info-1.svg';
import closeBtn from '../assets/union-1.svg';

import modalDictionary from '../../../locals/modal.json';

export function calculateFullAmount(partialAmount, partialPercent) {
  // Convert the percentage to a decimal
  let partialDecimal = partialPercent / 100;

  // Calculate the full amount
  let fullAmount = partialAmount / partialDecimal;

  // Round the result to 2 decimal places, always rounding up
  return parseFloat((Math.ceil(fullAmount * 100) / 100).toFixed(2));
}

export const MyWalletDepositModal = () => {
  const screenWidth = useWindowWidth();

  const { settings, setUpdateTimer } = useContext(PrivateContext);
  const {
    loadWalletInfoDepositModal,
    walletInfoDepositModal,
    walletListCrypto,
    loadCryptoWallet,
    walletListBank,
    loadBankWallet,
    allAccountsCrypto,
    allAccountsWire,
    loadAccounts,
    depositHandle,
    datePickerWireTransfer,
    datePickerWireExp,
    datePickerCryptoTransfer,
    withdrawFormData,
    setWithdrawFormData,
    withdrawHandle,
    modalDeposit,
    setModalDeposit,
    depositWireFormData,
    setDepositWireFormData,
    depositCryptoFormData,
    setDepositCryptoFormData,
    balancesNew,
    setDatePickerCryptoTransfer,
    depositSuccessData,
    setDepositSuccessData
  } = useContext(WalletContext);
  const [selectedItem, setSelectedItem] = useState(5);
  const [isCrypto, setIsCrypto] = useState(true);

  const submitCryptoHandle = () => {
    if (depositCryptoFormData.saving_balance === "") {
      toast.error("Please select a deposit type")
      return ""
    }

    depositCryptoFormData.expected_date = String('');
    // depositCryptoFormData.saving_balance = 1;
    // depositCryptoFormData.saving_balance = 50;
    depositCryptoFormData.expected_date = String('');
    depositHandle(depositCryptoFormData, {}, 'crypto');
  };

  const submitWireHandle = () => {
    console.log("depositWireFormData", depositWireFormData)
    console.log("depositCryptoFormData", depositCryptoFormData)
    if (depositCryptoFormData.saving_balance === "") {
      toast.error("Please select a deposit type")
      return ""
    }


    const formData = new FormData();
    formData.append('saving_balance', depositCryptoFormData.saving_balance);
    formData.append('account_id', depositWireFormData.account_id);
    formData.append('amount', depositWireFormData.amount);
    formData.append('bank_type', depositWireFormData.bank_type);

    formData.append(
      'transfer_date',
      depositWireFormData.transfer_date
    );
    formData.append(
      'expected_date',
      String(+new Date(depositWireFormData.expected_date))
    );

    
    formData.append(
      'request_description',
      depositWireFormData.request_description
    );
    formData.append(
      'transaction_document',
      depositWireFormData.transaction_document
    );
    formData.append(
      'transaction_document_3',
      depositWireFormData.transaction_document_3
    );
    formData.append(
      'transaction_document_2',
      depositWireFormData.transaction_document_2
    );

    depositHandle(formData, depositWireFormData, 'wire');
  };
  useEffect(() => {
    loadAccounts();
    loadBankWallet();
    loadWalletInfoDepositModal();
    loadCryptoWallet();
  }, []);

  useEffect(() => {
    setUpdateTimer(+new Date());
    modalDeposit === false && setDepositSuccessData(null);
  }, [modalDeposit]);

  const [error, setError] = useState(false);
  useEffect(() => {
    if (depositCryptoFormData && depositCryptoFormData?.transaction_hash) {
      const pattern = /^0x([A-Fa-f0-9]{64})$/;
      const result = isMatching(
        depositCryptoFormData.transaction_hash,
        pattern
      );
      setError(!result);
    }
  }, [depositCryptoFormData]);

  const [isShowBankDetails, setIsShowBankDetails] = useState(false);
  const [modalAreYouSure, setModalAreYouSure] = useState(false);

  useEffect(() => {
    setDepositCryptoFormData({
      ...depositCryptoFormData,
      account_id: []
    });
    setDepositWireFormData({
      ...depositWireFormData,
      account_id: []
    });
  }, [isCrypto]);

  return (
    <>
      {!depositSuccessData && modalDeposit && (
        <div
          className="blur-bg"
          onClick={() => {
            // setModalDeposit(false)

            setIsShowBankDetails(false);
          }}
        ></div>
      )}
      {!depositSuccessData && modalDeposit && (
        <div
          className="my-wallet-deposit-modal"
          style={{
            opacity: modalAreYouSure ? 0 : 1,
            display: modalAreYouSure ? 'none' : 'block'
          }}
        >
          <div
            className="modal-deposit-sm"
            style={{
              height:
                screenWidth < 564
                  ? '1463px'
                  : screenWidth >= 564 && screenWidth < 1037
                    ? '1392px'
                    : screenWidth >= 1037
                      ? '795px'
                      : undefined,
              width:
                screenWidth < 564
                  ? '420px'
                  : screenWidth >= 564 && screenWidth < 1037
                    ? '564px'
                    : screenWidth >= 1037
                      ? '1037px'
                      : undefined
            }}
          >
            {screenWidth < 564 && (
              <div className="content">
                <div className="left">
                  {isCrypto ? (
                    <>
                      {/* Crypto */}
                      <div className="description">
                        <div className="overlap-group">
                          <textarea
                            name=""
                            id=""
                            className="mod-inside-input"
                            style={{ height: '100%' }}
                            placeholder="Description"
                            cols={30}
                            rows={2}
                            value={depositCryptoFormData.request_description}
                            onChange={(e) =>
                              setDepositCryptoFormData({
                                ...depositCryptoFormData,
                                request_description: e.target.value
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="note">
                        <div className="overlap">
                          <p className="note-the-minimum">
                            <span className="span">
                              {modalDictionary['MODAL-title19']}
                            </span>
                            <span className="text-wrapper-2">
                              {' '}
                              {modalDictionary['MODAL-title20']}$
                              {settings ? settings?.minimum_deposit : '0'}{' '}
                              {modalDictionary['MODAL-title21']}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="inputs">
                        <div className="amount-input">
                          <div className="min-max">
                            <p className="min pointer">
                              <span
                                onClick={(e) => {
                                  if (
                                    depositCryptoFormData.saving_balance == 0
                                  ) {
                                    setDepositCryptoFormData({
                                      ...depositCryptoFormData,
                                      amount: Number(settings?.minimum_deposit)
                                    });
                                    setDepositWireFormData({
                                      ...depositWireFormData,
                                      amount: Number(settings?.minimum_deposit)
                                    });
                                  } else {
                                    setDepositWireFormData({
                                      ...depositWireFormData,
                                      amount: Number(
                                        settings?.minimum_deposit_stake
                                      )
                                    });
                                    setDepositCryptoFormData({
                                      ...depositCryptoFormData,
                                      amount: Number(
                                        settings?.minimum_deposit_stake
                                      )
                                    });
                                  }
                                }}
                              >
                                <span className="text-wrapper-3">
                                  {modalDictionary['MODAL-title4']}:
                                </span>
                                <span className="text-wrapper-4">&nbsp;</span>
                                <span className="text-wrapper-5">
                                  $
                                  {settings
                                    ? depositCryptoFormData.saving_balance == 0
                                      ? settings?.minimum_deposit
                                      : settings?.minimum_deposit_stake
                                    : '0'}
                                </span>
                              </span>

                              {depositCryptoFormData.saving_balance == 1 ? (
                                <>
                                  <span
                                    className="text-black ml-4"
                                    onClick={(e) => {
                                      if (
                                        depositCryptoFormData.saving_balance ==
                                        0
                                      ) {
                                        setDepositCryptoFormData({
                                          ...depositCryptoFormData,
                                          amount: Number(
                                            calculateFullAmount(
                                              settings?.minimum_deposit_stake,
                                              settings?.split_percentage_stake
                                            )
                                          )
                                        });
                                        setDepositWireFormData({
                                          ...depositWireFormData,
                                          amount: Number(
                                            calculateFullAmount(
                                              settings?.minimum_deposit_stake,
                                              settings?.split_percentage_stake
                                            )
                                          )
                                        });
                                      } else {
                                        setDepositCryptoFormData({
                                          ...depositCryptoFormData,
                                          amount: Number(
                                            calculateFullAmount(
                                              settings?.minimum_deposit_stake,
                                              settings?.split_percentage_stake
                                            )
                                          )
                                        });
                                        setDepositWireFormData({
                                          ...depositWireFormData,
                                          amount: Number(
                                            calculateFullAmount(
                                              settings?.minimum_deposit_stake,
                                              settings?.split_percentage_stake
                                            )
                                          )
                                        });
                                      }
                                    }}
                                  >
                                    (Required Amount: $
                                    {calculateFullAmount(
                                      settings?.minimum_deposit_stake,
                                      settings?.split_percentage_stake
                                    )}
                                    )
                                  </span>
                                </>
                              ) : null}
                            </p>
                            <p className="max-unlimited">
                              <span className="text-wrapper-3">
                                {modalDictionary['MODAL-title5']}:
                              </span>
                              <span className="text-wrapper-4">&nbsp;</span>
                              <span className="text-wrapper-5">
                                {modalDictionary['MODAL-title22']}
                              </span>
                            </p>
                          </div>
                          <div className="input">
                            <div className="div">
                              <div className="field">
                                <input
                                  type="text"
                                  placeholder="Enter an amount"
                                  className="mod-inside-input"
                                  value={depositCryptoFormData.amount}
                                  onChange={(e) => {
                                    100000000000 > +e.target.value &&
                                      setDepositCryptoFormData({
                                        ...depositCryptoFormData,
                                        amount: e.target.value
                                      });

                                    100000000000 > +e.target.value &&
                                      setDepositWireFormData({
                                        ...depositWireFormData,
                                        amount: e.target.value
                                      });
                                  }}
                                />
                              </div>

                              <Flex
                                width="fit-content"
                                y="center"
                                x="end"
                                gap={0.5}
                              >
                                <div
                                  className="tooltip-info tooltip-info-normal"
                                  style={{ right: 0, top: '10px' }}
                                >
                                  <div
                                    className="tooltip-info-icon"
                                    style={{
                                      height: 26
                                    }}
                                  >
                                    <div className="info">
                                      <div className="overlap-group-2">
                                        <img
                                          className="ellipse"
                                          alt="Ellipse"
                                          src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/ellipse-93.svg"
                                        />
                                        <img
                                          className="vector"
                                          alt="Vector"
                                          src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/vector-123.svg"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="tooltip-info-text"
                                    style={{ top: '30px' }}
                                  >
                                    <span>
                                      {modalDictionary['MODAL-title48']}
                                    </span>
                                  </div>
                                </div>
                              </Flex>
                            </div>
                          </div>
                        </div>
                        <div className="tx-input">
                          <div className="div">
                            <div className="field">
                              <input
                                type="text"
                                placeholder="Tx Hash"
                                className="mod-inside-input new-modal-input"
                                value={depositCryptoFormData.transaction_hash}
                                onChange={(e) => {
                                  setDepositCryptoFormData({
                                    ...depositCryptoFormData,
                                    transaction_hash: e.target.value.trim()
                                  });
                                }}
                              />
                            </div>
                            <Flex
                              width="fit-content"
                              y="center"
                              x="end"
                              gap={0.5}
                            >
                              <div
                                className="tooltip-info tooltip-info-normal"
                                style={{ right: 0, top: '10px' }}
                              >
                                <div
                                  className="tooltip-info-icon"
                                  style={{
                                    height: 26
                                  }}
                                >
                                  <div className="info">
                                    <div className="overlap-group-2">
                                      <img
                                        className="ellipse"
                                        alt="Ellipse"
                                        src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/ellipse-93-1.svg"
                                      />
                                      <img
                                        className="vector"
                                        alt="Vector"
                                        src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/vector-123-1.svg"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="tooltip-info-text"
                                  style={{ top: '30px' }}
                                >
                                  <span>
                                    {modalDictionary['MODAL-title47']}
                                  </span>
                                </div>
                              </div>
                            </Flex>
                          </div>
                        </div>
                        <div className="date-input">
                          <div className="overlap-2">
                            <div className="field">
                              <div className="value">
                                {modalDictionary['MODAL-title24']}
                              </div>
                            </div>
                            <Flex y="center" gap="1" marginB={0.8}>
                              <DatePicker
                                className="appf-form-input"
                                selected={datePickerCryptoTransfer}
                                onChange={(date: any): any => {
                                  setDatePickerCryptoTransfer(date);
                                  setDepositCryptoFormData({
                                    ...depositCryptoFormData,
                                    expected_date: +new Date(date)
                                  });
                                  setDepositCryptoFormData({
                                    ...depositCryptoFormData,
                                    transfer_date: +new Date(date)
                                  });
                                }}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                showTimeSelect
                                dateFormat="MMMM d, yyyy h:mm aa"
                              />
                            </Flex>
                          </div>
                        </div>
                        <div className="wallet-input">
                          <div className="text-wrapper-7 flex items-center">
                            {modalDictionary['MODAL-title24-1']}:
                            <Flex y="center" x="end" gap={0.5}>
                              <div className="tooltip-info tooltip-info-normal">
                                <div
                                  className="tooltip-info-icon"
                                  style={{
                                    height: 26
                                  }}
                                >
                                  <div className="icon !static">
                                    <img
                                      className="img"
                                      alt="Info"
                                      src={
                                        screenWidth >= 1037
                                          ? infoIcon
                                          : screenWidth >= 564 &&
                                            screenWidth < 1037
                                            ? infoIcon
                                            : undefined
                                      }
                                    />
                                  </div>
                                </div>
                                <div
                                  className="tooltip-info-text !left-full"
                                  style={{ top: '30px' }}
                                >
                                  <span>
                                    {modalDictionary['MODAL-title24-1-2']}
                                  </span>
                                </div>
                              </div>
                            </Flex>
                          </div>
                          <div className="field-wrapper">
                            <div
                              className="input-3 flex"
                              style={{
                                justifyContent: 'between',
                                width:
                                  screenWidth >= 1037
                                    ? '507px'
                                    : screenWidth >= 564 && screenWidth < 1037
                                      ? '524px'
                                      : undefined
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '1rem',
                                  justifyContent: 'space-between',
                                  width: '100%'
                                }}
                              >
                                <select
                                  style={{
                                    background: '#F5F5F5 !important',
                                    border: '0.5px solid #EEEEEE'
                                  }}
                                  className="appf-type-selector"
                                  value={depositCryptoFormData?.saving_balance}
                                  onChange={(e) => {
                                    setDepositCryptoFormData({
                                      ...depositCryptoFormData,
                                      saving_balance: e.target.value
                                    });

                                    setDepositWireFormData({
                                      ...depositWireFormData,
                                      saving_balance: e.target.value
                                    });
                                  }}
                                >
                                  <option value={''} selected={true}>
                                    Make a selection
                                  </option>
                                  <option value={1} >BOS Treasury | Strategic Diversification *Recommended</option>
                                  <option value={2}>Treasury Balance | 100% Collateralized</option>
                                  <option value={0}>Main Balance</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="wallet-input">
                          <div className="text-wrapper-7">
                            {modalDictionary['MODAL-title25']}:
                          </div>
                          <div className="field-wrapper">
                            {isCrypto ? (
                              <div style={{ display: 'flex', gap: '1rem' }}>
                                {allAccountsCrypto &&
                                  allAccountsCrypto?.data?.length > 0 && (
                                    <select
                                      style={{
                                        background: '#F5F5F5 !important',
                                        border: '0.5px solid #EEEEEE',
                                        width: '50%'
                                      }}
                                      className="appf-type-selector"

                                      onChange={(e) => {
                                        setSelectedItem(Number(e.target.value));
                                        setDepositCryptoFormData({
                                          ...depositCryptoFormData,
                                          account_id: e.target.value
                                        });
                                        setDepositWireFormData({
                                          ...depositWireFormData,
                                          account_id: e.target.value
                                        });
                                      }}
                                    >
                                      <option selected={true}>
                                        {dictionary['MY-WALLET-title33']}
                                      </option>
                                      {allAccountsCrypto?.data?.map(
                                        (
                                          type: {
                                            id: number;
                                            account_name: string;
                                          },
                                          index: number
                                        ) => {
                                          return (
                                            <option key={index} value={type.id}>
                                              {type.account_name}
                                            </option>
                                          );
                                        }
                                      )}
                                    </select>
                                  )}

                                {allAccountsCrypto &&
                                  allAccountsCrypto?.data?.length > 0 && (
                                    <select
                                      style={{
                                        background: '#F5F5F5 !important',
                                        border: '0.5px solid #EEEEEE',
                                        width: '50%'
                                      }}
                                      className="appf-type-selector"

                                      onChange={(e) => {
                                        setDepositCryptoFormData({
                                          ...depositCryptoFormData,
                                          token: e.target.value
                                        });
                                      }}
                                    >
                                      <option value={0} selected={true}>
                                        {modalDictionary['MODAL-title26']}
                                      </option>
                                      <option value={1}>USDT</option>
                                      <option value={2}>USDC</option>
                                    </select>
                                  )}
                              </div>
                            ) : (
                              <>
                                {allAccountsWire &&
                                  allAccountsWire?.data?.length > 0 && (
                                    <select
                                      style={{
                                        background: '#F5F5F5 !important',
                                        border: '0.5px solid #EEEEEE'
                                      }}
                                      className="appf-type-selector"

                                      onChange={(e) => {
                                        setSelectedItem(Number(e.target.value));
                                        setDepositCryptoFormData({
                                          ...depositCryptoFormData,
                                          account_id: e.target.value
                                        });
                                        setDepositWireFormData({
                                          ...depositWireFormData,
                                          account_id: e.target.value
                                        });
                                      }}
                                    >
                                      <option selected={true}>
                                        {dictionary['MY-WALLET-title33']}
                                      </option>
                                      {allAccountsWire?.data?.map(
                                        (
                                          type: {
                                            id: number;
                                            account_name: string;
                                          },
                                          index: number
                                        ) => {
                                          return (
                                            <option key={index} value={type.id}>
                                              {type.account_name}
                                            </option>
                                          );
                                        }
                                      )}
                                    </select>
                                  )}
                              </>
                            )}
                            {isCrypto ? (
                              <>
                                {allAccountsCrypto &&
                                  allAccountsCrypto?.data?.length === 0 && (
                                    <div className="warning-message-deposit">
                                      <p>{dictionary['MY-WALLET-title42']}</p>
                                      <p>
                                        <b>{dictionary['MY-WALLET-title43']}</b>{' '}
                                        {'->'}{' '}
                                        <Link to="/financial-account">
                                          <b className="link">
                                            {dictionary['MY-WALLET-title44']}
                                          </b>
                                        </Link>
                                      </p>
                                    </div>
                                  )}
                              </>
                            ) : (
                              <>
                                {allAccountsWire &&
                                  allAccountsWire?.data?.length === 0 && (
                                    <div className="warning-message-deposit">
                                      <p>{dictionary['MY-WALLET-title58']}</p>
                                      <p>
                                        <b>{dictionary['MY-WALLET-title59']}</b>{' '}
                                        {'->'}{' '}
                                        <Link to="/financial-account">
                                          <b className="link">
                                            {dictionary['MY-WALLET-title60']}
                                          </b>
                                        </Link>
                                      </p>
                                    </div>
                                  )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="details">
                        <div className="detail">
                          <div className="text-wrapper-8">
                            {modalDictionary['MODAL-title2']}
                          </div>
                          <div className="text-wrapper-9">
                            {balancesNew?.main_balance ||
                              balancesNew?.bos_interest ||
                              balancesNew?.main_balance === 0 ||
                              balancesNew?.bos_interest === 0 ? (
                              `$${numberWithCommas(
                                Number(balancesNew?.main_balance) +
                                Number(balancesNew?.bos_interest),
                                2
                              )}`
                            ) : (
                              <div className="skeleton-element-item skeleton-box"></div>
                            )}
                          </div>
                        </div>
                        <div className="detail">
                          <div className="title">
                            <div className="value-2">
                              {modalDictionary['MODAL-title28']}
                            </div>
                            <Flex
                              width="fit-content"
                              y="center"
                              x="end"
                              gap={0.5}
                            >
                              <div
                                className="tooltip-info tooltip-info-normal"
                                style={{ right: 0, top: '10px' }}
                              >
                                <div
                                  className="tooltip-info-icon"
                                  style={{
                                    height: 26
                                  }}
                                >
                                  <div className="icon">
                                    <img
                                      className="img"
                                      alt="Info"
                                      src={infoIcon}
                                    />
                                  </div>
                                </div>
                                <div
                                  className="tooltip-info-text"
                                  style={{ top: '30px' }}
                                >
                                  <span>
                                    {modalDictionary['MODAL-title23']}
                                  </span>
                                </div>
                              </div>
                            </Flex>
                          </div>
                          <div className="text-wrapper-10">
                            {depositCryptoFormData.saving_balance == 0 && (
                              <>{(depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee)}%</>
                            )}
                            {depositCryptoFormData.saving_balance == 1 && (
                              <>
                                {(depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee)}% /{' '}
                                {settings?.stake_deposit_fee}%
                              </>
                            )}
                            {depositCryptoFormData.saving_balance == 2 && (
                              <>{settings?.stake_deposit_fee}%</>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* Bank */}
                      <div
                        className="description"
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '1fr 1fr',
                          gap: '2rem'
                        }}
                      >
                        <div
                          className="overlap-group"
                          style={{
                            width: '100%',
                            minWidth: 180
                          }}
                        >
                          <textarea
                            name=""
                            id=""
                            className="mod-inside-input"
                            style={{ height: '100%' }}
                            placeholder="Description"
                            cols={30}
                            rows={2}
                            value={depositWireFormData.request_description}
                            onChange={(e) =>
                              setDepositWireFormData({
                                ...depositWireFormData,
                                request_description: e.target.value
                              })
                            }
                          />
                        </div>
                        <div className="mod-upload">
                          <Flex direction={'col'} gap={0.3} y={'center'}>
                            <div>
                              <label
                                className="mod-upload-label"
                                style={{
                                  fontSize: 14,
                                  color: '#153458'
                                }}
                              >
                                {dictionary['MY-WALLET-title61']}:
                              </label>
                              <input
                                type="file"
                                id="file-input"
                                name="file-input"
                                onChange={(e) => {
                                  setDepositWireFormData({
                                    ...depositWireFormData,
                                    transaction_document: e.target.files?.[0]
                                  });
                                }}
                              />
                            </div>
                            {/* Next */}
                            <div>
                              <label
                                className="mod-upload-label"
                                style={{
                                  fontSize: 14,
                                  color: '#153458'
                                }}
                              >
                                {dictionary['MY-WALLET-title131']}:
                              </label>
                              <input
                                type="file"
                                id="file-input"
                                name="file-input"
                                onChange={(e) => {
                                  setDepositWireFormData({
                                    ...depositWireFormData,
                                    transaction_document_2: e.target.files?.[0]
                                  });
                                }}
                              />
                            </div>
                            {/* Next */}
                            <div>
                              <label
                                className="mod-upload-label"
                                style={{
                                  fontSize: 14,
                                  color: '#153458'
                                }}
                              >
                                {dictionary['MY-WALLET-title132']}:
                              </label>
                              <input
                                type="file"
                                id="file-input"
                                name="file-input"
                                onChange={(e) => {
                                  setDepositWireFormData({
                                    ...depositWireFormData,
                                    transaction_document_3: e.target.files?.[0]
                                  });
                                }}
                              />
                            </div>
                          </Flex>
                        </div>
                      </div>
                      <div className="note">
                        <div className="overlap">
                          <p className="note-the-minimum">
                            <span className="span">
                              {modalDictionary['MODAL-title19']}
                            </span>
                            <span className="text-wrapper-2">
                              {' '}
                              {modalDictionary['MODAL-title20']}$
                              {settings ? settings?.minimum_deposit : '0'}{' '}
                              {modalDictionary['MODAL-title21']}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="inputs">
                        <div className="amount-input">
                          <div className="min-max">
                            <p className="min pointer">
                              <span
                                onClick={(e) => {
                                  if (
                                    depositCryptoFormData.saving_balance == 0
                                  ) {
                                    setDepositCryptoFormData({
                                      ...depositCryptoFormData,
                                      amount: Number(settings?.minimum_deposit)
                                    });
                                    setDepositWireFormData({
                                      ...depositWireFormData,
                                      amount: Number(settings?.minimum_deposit)
                                    });
                                  } else {
                                    setDepositWireFormData({
                                      ...depositWireFormData,
                                      amount: Number(
                                        settings?.minimum_deposit_stake
                                      )
                                    });
                                    setDepositCryptoFormData({
                                      ...depositCryptoFormData,
                                      amount: Number(
                                        settings?.minimum_deposit_stake
                                      )
                                    });
                                  }
                                }}
                              >
                                <span className="text-wrapper-3">
                                  {modalDictionary['MODAL-title4']}:
                                </span>
                                <span className="text-wrapper-4">&nbsp;</span>
                                <span className="text-wrapper-5">
                                  $
                                  {settings
                                    ? depositCryptoFormData.saving_balance == 0
                                      ? settings?.minimum_deposit
                                      : settings?.minimum_deposit_stake
                                    : '0'}
                                </span>
                              </span>

                              {depositCryptoFormData.saving_balance == 1 ? (
                                <>
                                  <span
                                    className="text-black ml-4"
                                    onClick={(e) => {
                                      if (
                                        depositCryptoFormData.saving_balance ==
                                        0
                                      ) {
                                        setDepositCryptoFormData({
                                          ...depositCryptoFormData,
                                          amount: Number(
                                            calculateFullAmount(
                                              settings?.minimum_deposit_stake,
                                              settings?.split_percentage_stake
                                            )
                                          )
                                        });
                                        setDepositWireFormData({
                                          ...depositWireFormData,
                                          amount: Number(
                                            calculateFullAmount(
                                              settings?.minimum_deposit_stake,
                                              settings?.split_percentage_stake
                                            )
                                          )
                                        });
                                      } else {
                                        setDepositCryptoFormData({
                                          ...depositCryptoFormData,
                                          amount: Number(
                                            calculateFullAmount(
                                              settings?.minimum_deposit_stake,
                                              settings?.split_percentage_stake
                                            )
                                          )
                                        });
                                        setDepositWireFormData({
                                          ...depositWireFormData,
                                          amount: Number(
                                            calculateFullAmount(
                                              settings?.minimum_deposit_stake,
                                              settings?.split_percentage_stake
                                            )
                                          )
                                        });
                                      }
                                    }}
                                  >
                                    (Required Amount: $
                                    {calculateFullAmount(
                                      settings?.minimum_deposit_stake,
                                      settings?.split_percentage_stake
                                    )}
                                    )
                                  </span>
                                </>
                              ) : null}
                            </p>
                            <p className="max-unlimited">
                              <span className="text-wrapper-3">
                                {modalDictionary['MODAL-title5']}:
                              </span>
                              <span className="text-wrapper-4">&nbsp;</span>
                              <span className="text-wrapper-5">
                                {modalDictionary['MODAL-title22']}
                              </span>
                            </p>
                          </div>
                          <div className="input">
                            <div className="div">
                              <div className="field">
                                <input
                                  type="text"
                                  placeholder="Enter an amount"
                                  className="mod-inside-input"
                                  value={depositCryptoFormData.amount}
                                  onChange={(e) => {
                                    100000000000 > +e.target.value &&
                                      setDepositCryptoFormData({
                                        ...depositCryptoFormData,
                                        amount: e.target.value
                                      });

                                    100000000000 > +e.target.value &&
                                      setDepositWireFormData({
                                        ...depositWireFormData,
                                        amount: e.target.value
                                      });
                                  }}
                                />
                              </div>
                              <Flex
                                width="fit-content"
                                y="center"
                                x="end"
                                gap={0.5}
                              >
                                <div
                                  className="tooltip-info tooltip-info-normal"
                                  style={{ right: 0, top: '10px' }}
                                >
                                  <div
                                    className="tooltip-info-icon"
                                    style={{
                                      height: 26
                                    }}
                                  >
                                    <div className="info">
                                      <div className="overlap-group-2">
                                        <img
                                          className="ellipse"
                                          alt="Ellipse"
                                          src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/ellipse-93.svg"
                                        />
                                        <img
                                          className="vector"
                                          alt="Vector"
                                          src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/vector-123.svg"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="tooltip-info-text"
                                    style={{ top: '30px' }}
                                  >
                                    <span>
                                      {modalDictionary['MODAL-title48']}
                                    </span>
                                  </div>
                                </div>
                              </Flex>
                            </div>
                          </div>
                        </div>
                        {/* <div className="tx-input">
                                                <div className="div">
                                                    <div className="field">
                                                        <div className="value">Expected data</div>
                                                    </div>

                                                    <div
                                                        style={{
                                                            left: 'calc(100% - 203px)',
                                                            position: 'relative',
                                                            top: '0.18rem',
                                                        }}
                                                    >
                                                        <DickPickerInputWireExp />
                                                    </div>
                                                </div>
                                            </div> */}

                        <div className="date-input">
                          <div className="overlap-2">
                            <div className="field">
                              <div className="value">
                                {modalDictionary['MODAL-title27']}
                              </div>
                            </div>
                            <div
                              style={{
                                left: 'calc(100% - 203px)',
                                position: 'relative',
                                top: '0.18rem'
                              }}
                            >
                              <DickPickerInputWireTransfer />
                            </div>
                          </div>
                        </div>
                        <div className="wallet-input">
                          <div className="text-wrapper-7 flex items-center">
                            {modalDictionary['MODAL-title24-1']}:
                            <Flex y="center" x="end" gap={0.5}>
                              <div className="tooltip-info tooltip-info-normal">
                                <div
                                  className="tooltip-info-icon"
                                  style={{
                                    height: 26
                                  }}
                                >
                                  <div className="icon !static">
                                    <img
                                      className="img"
                                      alt="Info"
                                      src={
                                        screenWidth >= 1037
                                          ? infoIcon
                                          : screenWidth >= 564 &&
                                            screenWidth < 1037
                                            ? infoIcon
                                            : undefined
                                      }
                                    />
                                  </div>
                                </div>
                                <div
                                  className="tooltip-info-text !left-full"
                                  style={{ top: '30px' }}
                                >
                                  <span>
                                    {modalDictionary['MODAL-title24-1-2']}
                                  </span>
                                </div>
                              </div>
                            </Flex>
                          </div>
                          <div
                            className="input-3 flex"
                            style={{
                              justifyContent: 'between',
                              width:
                                screenWidth >= 1037
                                  ? '507px'
                                  : screenWidth >= 564 && screenWidth < 1037
                                    ? '524px'
                                    : undefined
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '1rem',
                                justifyContent: 'space-between',
                                width: '100%'
                              }}
                            >
                              <select
                                style={{
                                  background: '#F5F5F5 !important',
                                  border: '0.5px solid #EEEEEE'
                                }}
                                className="appf-type-selector"

                                value={depositCryptoFormData?.saving_balance}
                                onChange={(e) => {
                                  setDepositCryptoFormData({
                                    ...depositCryptoFormData,
                                    saving_balance: e.target.value
                                  });

                                  setDepositWireFormData({
                                    ...depositWireFormData,
                                    saving_balance: e.target.value
                                  });
                                }}
                              >
                                <option value={''} selected={true}>
                                  Make a selection
                                </option>
                                <option value={1}>BOS Treasury | Strategic Diversification *Recommended</option>
                                <option value={2}>Treasury Balance | 100% Collateralized</option>
                                <option value={0}>Main Balance</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="wallet-input">
                          <div className="text-wrapper-7">
                            {modalDictionary['MODAL-title25']}:
                          </div>
                          <div className="field-wrapper">
                            {isCrypto ? (
                              <>
                                {allAccountsCrypto &&
                                  allAccountsCrypto?.data?.length > 0 && (
                                    <select
                                      style={{
                                        background: '#F5F5F5 !important',
                                        border: '0.5px solid #EEEEEE'
                                      }}
                                      className="appf-type-selector"

                                      onChange={(e) => {
                                        setSelectedItem(Number(e.target.value));
                                        setDepositCryptoFormData({
                                          ...depositCryptoFormData,
                                          account_id: e.target.value
                                        });
                                        setDepositWireFormData({
                                          ...depositWireFormData,
                                          account_id: e.target.value
                                        });
                                      }}
                                    >
                                      <option selected={true}>
                                        {dictionary['MY-WALLET-title33']}
                                      </option>
                                      {allAccountsCrypto?.data?.map(
                                        (
                                          type: {
                                            id: number;
                                            account_name: string;
                                          },
                                          index: number
                                        ) => {
                                          return (
                                            <option key={index} value={type.id}>
                                              {type.account_name}
                                            </option>
                                          );
                                        }
                                      )}
                                    </select>
                                  )}
                              </>
                            ) : (
                              <>
                                <div style={{ display: 'flex', gap: '1rem' }}>
                                  {allAccountsWire &&
                                    allAccountsWire?.data?.length > 0 && (
                                      <select
                                        style={{
                                          background: '#F5F5F5 !important',
                                          border: '0.5px solid #EEEEEE',
                                          width: '50%'
                                        }}
                                        className="appf-type-selector"

                                        onChange={(e) => {
                                          setSelectedItem(
                                            Number(e.target.value)
                                          );
                                          setDepositCryptoFormData({
                                            ...depositCryptoFormData,
                                            account_id: e.target.value
                                          });
                                          setDepositWireFormData({
                                            ...depositWireFormData,
                                            account_id: e.target.value
                                          });
                                        }}
                                      >
                                        <option selected={true}>
                                          {dictionary['MY-WALLET-title33']}
                                        </option>
                                        {allAccountsWire?.data?.map(
                                          (
                                            type: {
                                              id: number;
                                              account_name: string;
                                            },
                                            index: number
                                          ) => {
                                            return (
                                              <option
                                                key={index}
                                                value={type.id}
                                              >
                                                {type.account_name}
                                              </option>
                                            );
                                          }
                                        )}
                                      </select>
                                    )}

                                  <select
                                    style={{
                                      background: '#F5F5F5 !important',
                                      border: '0.5px solid #EEEEEE',
                                      width: '50%'
                                    }}
                                    className="appf-type-selector"
                                    onChange={(e) => {
                                      setDepositWireFormData({
                                        ...depositWireFormData,
                                        bank_type: e.target.value
                                      });
                                    }}
                                  >
                                    {/* <option value={0} selected={true}>
                                      {modalDictionary['MODAL-title51']}
                                    </option> */}
                                    {/* <option value={1}>IMBL</option> */}
                                    {/* <option value={2}>TCC</option> */}
                                    {/* <option value={3}>Caye Bank</option> */}
                                    <option value={4}>Chase Bank</option>
                                  </select>
                                </div>
                              </>
                            )}
                            {isCrypto ? (
                              <>
                                {allAccountsCrypto &&
                                  allAccountsCrypto?.data?.length === 0 && (
                                    <div className="warning-message-deposit">
                                      <p>{dictionary['MY-WALLET-title42']}</p>
                                      <p>
                                        <b>{dictionary['MY-WALLET-title43']}</b>{' '}
                                        {'->'}{' '}
                                        <Link to="/financial-account">
                                          <b className="link">
                                            {dictionary['MY-WALLET-title44']}
                                          </b>
                                        </Link>
                                      </p>
                                    </div>
                                  )}
                              </>
                            ) : (
                              <>
                                {allAccountsWire &&
                                  allAccountsWire?.data?.length === 0 && (
                                    <div className="warning-message-deposit">
                                      <p>{dictionary['MY-WALLET-title58']}</p>
                                      <p>
                                        <b>{dictionary['MY-WALLET-title59']}</b>{' '}
                                        {'->'}{' '}
                                        <Link to="/financial-account">
                                          <b className="link">
                                            {dictionary['MY-WALLET-title60']}
                                          </b>
                                        </Link>
                                      </p>
                                    </div>
                                  )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="details">
                        <div className="detail">
                          <div className="text-wrapper-8">
                            {modalDictionary['MODAL-title2']}
                          </div>
                          <div className="text-wrapper-9">
                            {balancesNew?.main_balance ||
                              balancesNew?.bos_interest ||
                              balancesNew?.main_balance === 0 ||
                              balancesNew?.bos_interest === 0 ? (
                              `$${numberWithCommas(
                                Number(balancesNew?.main_balance) +
                                Number(balancesNew?.bos_interest),
                                2
                              )}`
                            ) : (
                              <div className="skeleton-element-item skeleton-box"></div>
                            )}
                          </div>
                        </div>
                        <div className="detail">
                          <div className="title">
                            <div className="value-2">
                              {modalDictionary['MODAL-title28']}
                            </div>
                            <div className="icon">
                              <img className="img" alt="Info" src={infoIcon} />
                            </div>
                          </div>
                          <div className="text-wrapper-10">
                            {depositCryptoFormData.saving_balance == 0 && (
                              <>{(depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee)}%</>
                            )}
                            {depositCryptoFormData.saving_balance == 1 && (
                              <>
                                {(depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee)}% /{' '}
                                {settings?.stake_deposit_fee}%
                              </>
                            )}
                            {depositCryptoFormData.saving_balance == 2 && (
                              <>{settings?.stake_deposit_fee}%</>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="tabs">
                    <div className="frame">
                      <div className="crypto-tab">
                        <div
                          className={
                            isCrypto
                              ? 'div-wrapper pointer'
                              : 'overlap-3 pointer'
                          }
                        >
                          <div
                            className={
                              isCrypto ? 'text-wrapper-11' : 'text-wrapper-12'
                            }
                            onClick={() => setIsCrypto(true)}
                          >
                            {modalDictionary['MODAL-title30']}
                          </div>
                        </div>
                      </div>
                      <div className="bank-tab">
                        <div
                          className={
                            isCrypto
                              ? 'overlap-3 pointer'
                              : 'div-wrapper pointer'
                          }
                        >
                          <div
                            className={
                              isCrypto ? 'text-wrapper-12' : 'text-wrapper-11'
                            }
                            onClick={() => {
                              setIsCrypto(false);
                              setIsShowBankDetails(false);
                            }}
                          >
                            {modalDictionary['MODAL-title31']}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {isCrypto ? (
                  <div className="right">
                    {/* Crypto */}
                    <div className="content-2">
                      <div className="wallet-info">
                        <div className="qr">
                          {walletInfoDepositModal ? (
                            <QRCodeSVG
                              style={{
                                background: 'white',
                                padding: '1rem'
                              }}
                              value={
                                walletInfoDepositModal?.crypto?.[0]?.address
                              }
                              size={150}
                              bgColor={'#ffffff'}
                              fgColor={'#000000'}
                              level={'L'}
                              includeMargin={false}
                            />
                          ) : (
                            <img
                              className="mod-img"
                              src="/images/QRCode.png"
                              alt="QRCode"
                            />
                          )}
                          <p className="p">{dictionary['MY-WALLET-title41']}</p>
                        </div>
                        <img
                          className="line"
                          alt="Line"
                          src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/line.svg"
                        />
                        <div className="wallet">
                          <div
                            className="wallet-address"
                            dangerouslySetInnerHTML={{
                              __html:
                                walletInfoDepositModal?.crypto?.[0]?.address
                            }}
                          ></div>
                          <CopyToClipboard
                            text={walletInfoDepositModal?.crypto?.[0]?.address}
                            onCopy={() => {
                              toast.success('Copied Successfully.');
                            }}
                          >
                            <div className="copy-btn pointer">
                              <div className="text">Copy</div>
                            </div>
                          </CopyToClipboard>
                        </div>
                      </div>
                      <div className="result-info">
                        <div className="bg" />
                        <div className="payment-details">
                          <div className="div-2">
                            <div className="item">
                              <div className="text-wrapper-8">
                                {modalDictionary['MODAL-title7']}
                              </div>
                              <div className="text-wrapper-9">Deposit</div>
                            </div>
                            <div className="item">
                              <div className="text-wrapper-8">
                                {modalDictionary['MODAL-title8']}
                              </div>
                              <div className="text-wrapper-9">
                                $
                                {numberWithCommas(
                                  depositCryptoFormData.amount,
                                  0
                                )}
                              </div>
                            </div>
                          </div>
                          <img
                            className="line-2"
                            alt="Line"
                            src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/line-1.svg"
                          />
                          <div className="div-2">
                            <div className="item">
                              <div className="title-2">
                                <div className="text-wrapper-13">
                                  {modalDictionary['MODAL-title28']}
                                </div>

                                <Flex
                                  width="fit-content"
                                  y="center"
                                  x="end"
                                  gap={0.5}
                                >
                                  <div
                                    className="tooltip-info tooltip-info-normal"
                                    style={{ right: 0, top: '10px' }}
                                  >
                                    <div
                                      className="tooltip-info-icon"
                                      style={{
                                        height: 26
                                      }}
                                    >
                                      <div className="info-wrapper">
                                        <img
                                          className="img"
                                          alt="Info"
                                          src={infoIcon}
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="tooltip-info-text"
                                      style={{ top: '30px' }}
                                    >
                                      <span>
                                        {modalDictionary['MODAL-title23']}
                                      </span>
                                    </div>
                                  </div>
                                </Flex>
                              </div>
                              <div className="text-wrapper-9">
                                {depositCryptoFormData.saving_balance == 0 && (
                                  <>
                                    $
                                    {numberWithCommas(
                                      depositCryptoFormData.amount *
                                      ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100)
                                    )}
                                  </>
                                )}
                                {depositCryptoFormData.saving_balance == 1 && (
                                  <>
                                    $
                                    {numberWithCommas(
                                      depositCryptoFormData.amount *
                                      ((100 -
                                        Number(
                                          settings?.split_percentage_stake
                                        )) /
                                        100) *
                                      ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100)
                                    )}{' '}
                                    / $
                                    {numberWithCommas(
                                      depositCryptoFormData.amount *
                                      (Number(
                                        settings?.split_percentage_stake
                                      ) /
                                        100) *
                                      (settings?.stake_deposit_fee / 100)
                                    )}
                                  </>
                                )}
                                {depositCryptoFormData.saving_balance == 2 && (
                                  <>
                                    $
                                    {numberWithCommas(
                                      depositCryptoFormData.amount *
                                      (settings?.stake_deposit_fee / 100)
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="item">
                              <div className="text-wrapper-8">
                                {modalDictionary['MODAL-title29']}
                              </div>
                              <div className="text-wrapper-9">
                                {depositCryptoFormData.saving_balance == 0 && (
                                  <>
                                    $
                                    {numberWithCommas(
                                      Number(depositCryptoFormData.amount) -
                                      Number(depositCryptoFormData.amount) *
                                      ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100)
                                    )}
                                  </>
                                )}

                                {depositCryptoFormData.saving_balance == 1 && (
                                  <>
                                    $
                                    {numberWithCommas(
                                      Number(
                                        depositCryptoFormData.amount *
                                        ((100 -
                                          Number(
                                            settings?.split_percentage_stake
                                          )) /
                                          100)
                                      ) -
                                      Number(depositCryptoFormData.amount) *
                                      ((100 -
                                        Number(
                                          settings?.split_percentage_stake
                                        )) /
                                        100) *
                                      ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100)
                                    )}{' '}
                                    / $
                                    {numberWithCommas(
                                      Number(
                                        depositCryptoFormData.amount *
                                        (Number(
                                          settings?.split_percentage_stake
                                        ) /
                                          100)
                                      ) -
                                      Number(depositCryptoFormData.amount) *
                                      (Number(
                                        settings?.split_percentage_stake
                                      ) /
                                        100) *
                                      (settings?.stake_deposit_fee / 100)
                                    )}
                                  </>
                                )}

                                {depositCryptoFormData.saving_balance == 2 && (
                                  <>
                                    $
                                    {numberWithCommas(
                                      Number(depositCryptoFormData.amount) -
                                      Number(depositCryptoFormData.amount) *
                                      (settings?.stake_deposit_fee / 100)
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="item">
                              <p className="text-wrapper-8 flex gap-x-1 items-center">
                                {depositCryptoFormData.saving_balance == 0 && (
                                  <>{modalDictionary['MODAL-title9']}</>
                                )}
                                {depositCryptoFormData.saving_balance == 1 && (
                                  <>
                                    {modalDictionary['MODAL-title9-1']}
                                    <Flex
                                      width="fit-content"
                                      y="center"
                                      x="end"
                                      gap={0.5}
                                    >
                                      <div
                                        className="tooltip-info tooltip-info-normal"
                                        style={{ right: 0, top: '10px' }}
                                      >
                                        <div
                                          className="tooltip-info-icon"
                                          style={{
                                            height: 26
                                          }}
                                        >
                                          <div className="info-wrapper">
                                            <img
                                              className="img"
                                              alt="Info"
                                              src={infoIcon}
                                            />
                                          </div>
                                        </div>
                                        <div
                                          className="tooltip-info-text"
                                          style={{ top: '30px' }}
                                        >
                                          <span>
                                            {
                                              modalDictionary[
                                              'MODAL-title9-1-1'
                                              ]
                                            }
                                          </span>
                                        </div>
                                      </div>
                                    </Flex>
                                  </>
                                )}
                                {depositCryptoFormData.saving_balance == 2 && (
                                  <>{modalDictionary['MODAL-title9-2']}</>
                                )}
                              </p>
                              <div className="text-wrapper-9">
                                {depositCryptoFormData.saving_balance == 0 && (
                                  <>
                                    {numberWithCommas(
                                      Number(balancesNew?.main_balance) +
                                      Number(balancesNew?.bos_interest) +
                                      (Number(depositCryptoFormData.amount) -
                                        Number(depositCryptoFormData.amount) *
                                        ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100))
                                    )}
                                  </>
                                )}
                                {depositCryptoFormData.saving_balance == 1 && (
                                  <>
                                    $
                                    {numberWithCommas(
                                      Number(balancesNew?.main_balance) +
                                      Number(balancesNew?.bos_interest) +
                                      (Number(
                                        depositCryptoFormData.amount *
                                        ((100 -
                                          Number(
                                            settings?.split_percentage_stake
                                          )) /
                                          100)
                                      ) -
                                        depositCryptoFormData.amount *
                                        ((100 -
                                          Number(
                                            settings?.split_percentage_stake
                                          )) /
                                          100) *
                                        ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100))
                                    )}{' '}
                                    / $
                                    {numberWithCommas(
                                      Number(balancesNew?.treasury_balance) +
                                      (Number(
                                        depositCryptoFormData.amount *
                                        (Number(
                                          settings?.split_percentage_stake
                                        ) /
                                          100)
                                      ) -
                                        depositCryptoFormData.amount *
                                        (Number(
                                          settings?.split_percentage_stake
                                        ) /
                                          100) *
                                        (settings?.stake_deposit_fee / 100))
                                    )}
                                  </>
                                )}

                                {depositCryptoFormData.saving_balance == 2 && (
                                  <>
                                    $
                                    {numberWithCommas(
                                      Number(balancesNew?.treasury_balance) +
                                      (Number(depositCryptoFormData.amount) -
                                        Number(depositCryptoFormData.amount) *
                                        (settings?.stake_deposit_fee / 100))
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="submit-btn"
                      onClick={() => {
                        if (
                          depositCryptoFormData?.transaction_hash?.trim()
                            .length > 65
                        ) {
                          if (
                            depositCryptoFormData.saving_balance == 0 ||
                            depositCryptoFormData.saving_balance == 1 ||
                            depositCryptoFormData.saving_balance == 2
                          ) {
                            if (depositCryptoFormData?.token > 0) {
                              if (
                                depositCryptoFormData?.account_id.length > 0
                              ) {
                                if (depositCryptoFormData.saving_balance == 1) {
                                  if (
                                    depositCryptoFormData?.amount >=
                                    Number(
                                      calculateFullAmount(
                                        settings?.minimum_deposit_stake,
                                        settings?.split_percentage_stake
                                      )
                                    )
                                  ) {
                                    !error && submitCryptoHandle();
                                  } else {
                                    toast.error(
                                      `For this split deposit, you need to deposit a total amount of $${calculateFullAmount(
                                        settings?.minimum_deposit_stake,
                                        settings?.split_percentage_stake
                                      )} to reach the minimum deposit amount of $${settings?.minimum_deposit_stake
                                      } for Treasury Balance.`
                                    );
                                  }
                                } else {
                                  !error && submitCryptoHandle();
                                }
                              } else {
                                toast.error('Please select a wallet.');
                              }
                            } else {
                              toast.error('Please select a cryptocurrency.');
                            }
                          } else {
                            toast.error('Please select a deposit type.');
                          }
                        } else {
                          toast.error(
                            'Invalid Tx Hash, it must be 66 characters.'
                          );
                        }
                      }}
                    >
                      <div className="overlap-group-3 pointer">
                        <div className="text-wrapper-14">
                          {modalDictionary['MODAL-title13']}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="right">
                    {/* Bank */}
                    <div className="content-2">
                      <div
                        className="deposit-ck-editor-data"
                        dangerouslySetInnerHTML={{
                          __html: isShowBankDetails
                            ? settings?.bank_info
                            : walletInfoDepositModal?.bank?.[0]?.address
                        }}
                      ></div>
                      <div className="deposit-ck-editor-data-switch">
                        <div
                          className={
                            isShowBankDetails
                              ? 'deposit-ck-editor-data-switch-btn pointer'
                              : 'deposit-ck-editor-data-switch-btn pointer active'
                          }
                          onClick={() => setIsShowBankDetails(false)}
                        >
                          Bank Instructions
                        </div>
                        <div
                          className={
                            isShowBankDetails
                              ? 'deposit-ck-editor-data-switch-btn pointer active'
                              : 'deposit-ck-editor-data-switch-btn pointer'
                          }
                          onClick={() => setIsShowBankDetails(true)}
                        >
                          Bank Details
                        </div>
                      </div>
                      <div className="result-info">
                        <div className="bg" />
                        <div className="payment-details">
                          <div className="div-2">
                            <div className="item">
                              <div className="text-wrapper-8">
                                {modalDictionary['MODAL-title7']}
                              </div>
                              <div className="text-wrapper-9">Deposit</div>
                            </div>
                            <div className="item">
                              <div className="text-wrapper-8">
                                {modalDictionary['MODAL-title8']}
                              </div>
                              <div className="text-wrapper-9">
                                $
                                {numberWithCommas(
                                  depositWireFormData.amount,
                                  0
                                )}
                              </div>
                            </div>
                          </div>
                          <img
                            className="line-2"
                            alt="Line"
                            src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/line-1.svg"
                          />
                          <div className="div-2">
                            <div className="item">
                              <div className="title-2">
                                <div className="text-wrapper-13">
                                  {modalDictionary['MODAL-title28']}
                                </div>
                                <Flex
                                  width="fit-content"
                                  y="center"
                                  x="end"
                                  gap={0.5}
                                >
                                  <div
                                    className="tooltip-info tooltip-info-normal"
                                    style={{ right: 0, top: '10px' }}
                                  >
                                    <div
                                      className="tooltip-info-icon"
                                      style={{
                                        height: 26
                                      }}
                                    >
                                      <div className="info-wrapper">
                                        <img
                                          className="img"
                                          alt="Info"
                                          src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/info-1.svg"
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="tooltip-info-text"
                                      style={{ top: '30px' }}
                                    >
                                      <span>
                                        {modalDictionary['MODAL-title23']}
                                      </span>
                                    </div>
                                  </div>
                                </Flex>
                              </div>
                              <div className="text-wrapper-9">
                                {depositCryptoFormData.saving_balance == 0 && (
                                  <>
                                    $
                                    {numberWithCommas(
                                      depositCryptoFormData.amount *
                                      ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100)
                                    )}
                                  </>
                                )}
                                {depositCryptoFormData.saving_balance == 1 && (
                                  <>
                                    $
                                    {numberWithCommas(
                                      depositCryptoFormData.amount *
                                      ((100 -
                                        Number(
                                          settings?.split_percentage_stake
                                        )) /
                                        100) *
                                      ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100)
                                    )}{' '}
                                    / $
                                    {numberWithCommas(
                                      depositCryptoFormData.amount *
                                      (Number(
                                        settings?.split_percentage_stake
                                      ) /
                                        100) *
                                      (settings?.stake_deposit_fee / 100)
                                    )}
                                  </>
                                )}
                                {depositCryptoFormData.saving_balance == 2 && (
                                  <>
                                    $
                                    {numberWithCommas(
                                      depositCryptoFormData.amount *
                                      (settings?.stake_deposit_fee / 100)
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="item">
                              <div className="text-wrapper-8">
                                {modalDictionary['MODAL-title29']}
                              </div>
                              <div className="text-wrapper-9">
                                {depositCryptoFormData.saving_balance == 0 && (
                                  <>
                                    $
                                    {numberWithCommas(
                                      Number(depositCryptoFormData.amount) -
                                      Number(depositCryptoFormData.amount) *
                                      ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100)
                                    )}
                                  </>
                                )}

                                {depositCryptoFormData.saving_balance == 1 && (
                                  <>
                                    $
                                    {numberWithCommas(
                                      Number(
                                        depositCryptoFormData.amount *
                                        ((100 -
                                          Number(
                                            settings?.split_percentage_stake
                                          )) /
                                          100)
                                      ) -
                                      Number(depositCryptoFormData.amount) *
                                      ((100 -
                                        Number(
                                          settings?.split_percentage_stake
                                        )) /
                                        100) *
                                      ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100)
                                    )}{' '}
                                    / $
                                    {numberWithCommas(
                                      Number(
                                        depositCryptoFormData.amount *
                                        (Number(
                                          settings?.split_percentage_stake
                                        ) /
                                          100)
                                      ) -
                                      Number(depositCryptoFormData.amount) *
                                      (Number(
                                        settings?.split_percentage_stake
                                      ) /
                                        100) *
                                      (settings?.stake_deposit_fee / 100)
                                    )}
                                  </>
                                )}

                                {depositCryptoFormData.saving_balance == 2 && (
                                  <>
                                    $
                                    {numberWithCommas(
                                      Number(depositCryptoFormData.amount) -
                                      Number(depositCryptoFormData.amount) *
                                      (settings?.stake_deposit_fee / 100)
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="item">
                              <p className="text-wrapper-8 flex gap-x-1 items-center">
                                {depositCryptoFormData.saving_balance == 0 && (
                                  <>{modalDictionary['MODAL-title9']}</>
                                )}
                                {depositCryptoFormData.saving_balance == 1 && (
                                  <>
                                    {modalDictionary['MODAL-title9-1']}
                                    <Flex
                                      width="fit-content"
                                      y="center"
                                      x="end"
                                      gap={0.5}
                                    >
                                      <div
                                        className="tooltip-info tooltip-info-normal"
                                        style={{ right: 0, top: '10px' }}
                                      >
                                        <div
                                          className="tooltip-info-icon"
                                          style={{
                                            height: 26
                                          }}
                                        >
                                          <div className="info-wrapper">
                                            <img
                                              className="img"
                                              alt="Info"
                                              src={infoIcon}
                                            />
                                          </div>
                                        </div>
                                        <div
                                          className="tooltip-info-text"
                                          style={{ top: '30px' }}
                                        >
                                          <span>
                                            {
                                              modalDictionary[
                                              'MODAL-title9-1-1'
                                              ]
                                            }
                                          </span>
                                        </div>
                                      </div>
                                    </Flex>
                                  </>
                                )}
                                {depositCryptoFormData.saving_balance == 2 && (
                                  <>{modalDictionary['MODAL-title9-2']}</>
                                )}
                              </p>
                              <div className="text-wrapper-9">
                                {depositCryptoFormData.saving_balance == 0 && (
                                  <>
                                    $
                                    {numberWithCommas(
                                      Number(depositCryptoFormData.amount) -
                                      depositCryptoFormData.amount *
                                      ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100)
                                    )}
                                  </>
                                )}

                                {depositCryptoFormData.saving_balance == 1 && (
                                  <>
                                    $
                                    {numberWithCommas(
                                      Number(
                                        depositCryptoFormData.amount *
                                        ((100 -
                                          Number(
                                            settings?.split_percentage_stake
                                          )) /
                                          100)
                                      ) -
                                      depositCryptoFormData.amount *
                                      ((100 -
                                        Number(
                                          settings?.split_percentage_stake
                                        )) /
                                        100) *
                                      ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100)
                                    )}{' '}
                                    / $
                                    {numberWithCommas(
                                      Number(
                                        depositCryptoFormData.amount *
                                        (Number(
                                          settings?.split_percentage_stake
                                        ) /
                                          100)
                                      ) -
                                      depositCryptoFormData.amount *
                                      (Number(
                                        settings?.split_percentage_stake
                                      ) /
                                        100) *
                                      (settings?.stake_deposit_fee / 100)
                                    )}
                                  </>
                                )}

                                {depositCryptoFormData.saving_balance == 2 && (
                                  <>
                                    $
                                    {numberWithCommas(
                                      Number(depositCryptoFormData.amount) -
                                      depositCryptoFormData.amount *
                                      (settings?.stake_deposit_fee / 100)
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="submit-btn"
                      onClick={() => {
                        if (
                          allAccountsWire &&
                          allAccountsWire?.data?.length === 0
                        ) {
                          toast.error('You need to add a wallet first');
                        } else {
                          if (depositWireFormData.bank_type) {
                            if (
                              depositCryptoFormData.saving_balance == 0 ||
                              depositCryptoFormData.saving_balance == 1 ||
                              depositCryptoFormData.saving_balance == 2
                            ) {
                              if (depositWireFormData?.account_id.length > 0) {
                                if (depositCryptoFormData.saving_balance == 1) {
                                  if (
                                    depositWireFormData?.amount >=
                                    Number(
                                      calculateFullAmount(
                                        settings?.minimum_deposit_stake,
                                        settings?.split_percentage_stake
                                      )
                                    )
                                  ) {
                                    submitWireHandle();
                                  } else {
                                    toast.error(
                                      `For this split deposit, you need to deposit a total amount of $${calculateFullAmount(
                                        settings?.minimum_deposit_stake,
                                        settings?.split_percentage_stake
                                      )} to reach the minimum deposit amount of $${settings?.minimum_deposit_stake
                                      } for Treasury Balance.`
                                    );
                                  }
                                } else {
                                  submitWireHandle();
                                }
                              } else {
                                toast.error('Please select an account');
                              }
                            } else {
                              toast.error('Please select a deposit type.');
                            }
                          } else {
                            toast.error(
                              'Please select the correct BOS Bank Name to continue.'
                            );
                          }
                        }
                      }}
                    >
                      <div className="overlap-group-3 pointer">
                        <div className="text-wrapper-14">
                          {modalDictionary['MODAL-title13']}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {(screenWidth >= 1037 ||
              (screenWidth >= 564 && screenWidth < 1037)) && (
                <>
                  <div
                    className="content-3"
                    style={{
                      alignItems:
                        screenWidth >= 564 && screenWidth < 1037
                          ? 'flex-start'
                          : screenWidth >= 1037
                            ? 'center'
                            : undefined,
                      flexDirection:
                        screenWidth >= 564 && screenWidth < 1037
                          ? 'column'
                          : undefined,
                      gap:
                        screenWidth >= 564 && screenWidth < 1037
                          ? '42px'
                          : undefined,
                      justifyContent:
                        screenWidth >= 1037 ? 'space-between' : undefined,
                      left:
                        screenWidth >= 564 && screenWidth < 1037
                          ? '20px'
                          : screenWidth >= 1037
                            ? '34px'
                            : undefined,
                      width:
                        screenWidth >= 564 && screenWidth < 1037
                          ? '524px'
                          : screenWidth >= 1037
                            ? '974px'
                            : undefined
                    }}
                  >
                    <div
                      className="left-2"
                      style={{
                        width:
                          screenWidth >= 1037
                            ? '507.5px'
                            : screenWidth >= 564 && screenWidth < 1037
                              ? '524px'
                              : undefined
                      }}
                    >
                      {isCrypto ? (
                        <>
                          {/* Crypto */}
                          <div
                            className="overlap-group-wrapper crypto"
                            style={{
                              left:
                                screenWidth >= 1037
                                  ? '0'
                                  : screenWidth >= 564 && screenWidth < 1037
                                    ? '1px'
                                    : undefined,
                              width:
                                screenWidth >= 1037
                                  ? '509px'
                                  : screenWidth >= 564 && screenWidth < 1037
                                    ? '525px'
                                    : undefined
                            }}
                          >
                            <div
                              className="overlap-group-4 crypto"
                              style={{
                                width:
                                  screenWidth >= 1037
                                    ? '507px'
                                    : screenWidth >= 564 && screenWidth < 1037
                                      ? '523px'
                                      : undefined
                              }}
                            >
                              <textarea
                                name=""
                                id=""
                                className="mod-inside-input"
                                style={{ height: '100%' }}
                                placeholder="Description"
                                cols={30}
                                rows={2}
                                value={depositCryptoFormData.request_description}
                                onChange={(e) =>
                                  setDepositCryptoFormData({
                                    ...depositCryptoFormData,
                                    request_description: e.target.value
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div
                            className="overlap-wrapper crypto"
                            style={{
                              left:
                                screenWidth >= 1037
                                  ? '0'
                                  : screenWidth >= 564 && screenWidth < 1037
                                    ? '1px'
                                    : undefined,
                              width:
                                screenWidth >= 1037
                                  ? '509px'
                                  : screenWidth >= 564 && screenWidth < 1037
                                    ? '525px'
                                    : undefined
                            }}
                          >
                            <div
                              className="note-the-minimum-wrapper"
                              style={{
                                backgroundImage:
                                  screenWidth >= 1037
                                    ? 'url(https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/bg-3.svg)'
                                    : screenWidth >= 564 && screenWidth < 1037
                                      ? 'url(https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/bg-5.svg)'
                                      : undefined,
                                width:
                                  screenWidth >= 1037
                                    ? '507px'
                                    : screenWidth >= 564 && screenWidth < 1037
                                      ? '523px'
                                      : undefined
                              }}
                            >
                              <p
                                className="note-the-minimum-2"
                                style={{
                                  left:
                                    screenWidth >= 1037
                                      ? '41px'
                                      : screenWidth >= 564 && screenWidth < 1037
                                        ? '42px'
                                        : undefined,
                                  whiteSpace:
                                    screenWidth >= 1037 ? 'nowrap' : undefined,
                                  width:
                                    screenWidth >= 564 && screenWidth < 1037
                                      ? '439px'
                                      : undefined
                                }}
                              >
                                <span className="span">
                                  {modalDictionary['MODAL-title19']}
                                </span>
                                <span className="text-wrapper-2">
                                  {' '}
                                  The minimum deposit for your{' '}
                                  {depositCryptoFormData.saving_balance == 1 ||
                                    depositCryptoFormData.saving_balance == 2
                                    ? 'Treasury Balance'
                                    : 'BOS Main Balance'}{' '}
                                  is $
                                  {depositCryptoFormData.saving_balance == 0
                                    ? settings?.minimum_deposit
                                    : depositCryptoFormData.saving_balance == 1
                                      ? calculateFullAmount(
                                        settings?.minimum_deposit_stake,
                                        settings?.split_percentage_stake
                                      )
                                      : settings?.minimum_deposit_stake}
                                  {modalDictionary['MODAL-title21']}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div
                            className="inputs-2"
                            style={{
                              width:
                                screenWidth >= 1037
                                  ? '507px'
                                  : screenWidth >= 564 && screenWidth < 1037
                                    ? '524px'
                                    : undefined
                            }}
                          >
                            <div
                              className="amount-input-2 amount-input-2-b"
                              style={{
                                width:
                                  screenWidth >= 1037
                                    ? '507px'
                                    : screenWidth >= 564 && screenWidth < 1037
                                      ? '523px'
                                      : undefined
                              }}
                            >
                              <div
                                className="min-max-2"
                                style={{
                                  width:
                                    screenWidth >= 1037
                                      ? '484px'
                                      : screenWidth >= 564 && screenWidth < 1037
                                        ? '500px'
                                        : undefined
                                }}
                              >
                                <p className="min pointer">
                                  <span
                                    onClick={(e) => {
                                      if (
                                        depositCryptoFormData.saving_balance == 1
                                      ) {
                                        setDepositCryptoFormData({
                                          ...depositCryptoFormData,
                                          amount: Number(
                                            calculateFullAmount(
                                              settings?.minimum_deposit_stake,
                                              settings?.split_percentage_stake
                                            )
                                          )
                                        });
                                        setDepositWireFormData({
                                          ...depositWireFormData,
                                          amount: Number(
                                            calculateFullAmount(
                                              settings?.minimum_deposit_stake,
                                              settings?.split_percentage_stake
                                            )
                                          )
                                        });
                                      } else if (
                                        depositCryptoFormData.saving_balance == 0
                                      ) {
                                        setDepositCryptoFormData({
                                          ...depositCryptoFormData,
                                          amount: Number(
                                            settings?.minimum_deposit
                                          )
                                        });
                                        setDepositWireFormData({
                                          ...depositWireFormData,
                                          amount: Number(
                                            settings?.minimum_deposit
                                          )
                                        });
                                      } else {
                                        setDepositWireFormData({
                                          ...depositWireFormData,
                                          amount: Number(
                                            settings?.minimum_deposit_stake
                                          )
                                        });
                                        setDepositCryptoFormData({
                                          ...depositCryptoFormData,
                                          amount: Number(
                                            settings?.minimum_deposit_stake
                                          )
                                        });
                                      }
                                    }}
                                  >
                                    <span className="text-wrapper-3">
                                      {modalDictionary['MODAL-title4']}:
                                    </span>
                                    <span className="text-wrapper-4">&nbsp;</span>
                                    <span className="text-wrapper-5">
                                      $
                                      {settings
                                        ? depositCryptoFormData.saving_balance ==
                                          1
                                          ? calculateFullAmount(
                                            settings?.minimum_deposit_stake,
                                            settings?.split_percentage_stake
                                          )
                                          : depositCryptoFormData.saving_balance ==
                                            0
                                            ? settings?.minimum_deposit
                                            : settings?.minimum_deposit_stake
                                        : '0'}
                                    </span>
                                  </span>

                                  {/* {depositCryptoFormData.saving_balance == 1 ? (
                                  <>
                                    <span
                                      className="text-black ml-4"
                                      onClick={(e) => {
                                        if (
                                          depositCryptoFormData.saving_balance ==
                                          0
                                        ) {
                                          setDepositCryptoFormData({
                                            ...depositCryptoFormData,
                                            amount: Number(
                                              calculateFullAmount(
                                                settings?.minimum_deposit_stake,
                                                settings?.split_percentage_stake
                                              )
                                            )
                                          });
                                          setDepositWireFormData({
                                            ...depositWireFormData,
                                            amount: Number(
                                              calculateFullAmount(
                                                settings?.minimum_deposit_stake,
                                                settings?.split_percentage_stake
                                              )
                                            )
                                          });
                                        } else {
                                          setDepositCryptoFormData({
                                            ...depositCryptoFormData,
                                            amount: Number(
                                              calculateFullAmount(
                                                settings?.minimum_deposit_stake,
                                                settings?.split_percentage_stake
                                              )
                                            )
                                          });
                                          setDepositWireFormData({
                                            ...depositWireFormData,
                                            amount: Number(
                                              calculateFullAmount(
                                                settings?.minimum_deposit_stake,
                                                settings?.split_percentage_stake
                                              )
                                            )
                                          });
                                        }
                                      }}
                                    >
                                      (Required Amount: $
                                      {calculateFullAmount(
                                        settings?.minimum_deposit_stake,
                                        settings?.split_percentage_stake
                                      )}
                                      )
                                    </span>
                                  </>
                                ) : null} */}
                                </p>
                                <p className="max-unlimited">
                                  <span className="text-wrapper-3">
                                    {modalDictionary['MODAL-title5']}:
                                  </span>
                                  <span className="text-wrapper-4">&nbsp;</span>
                                  <span className="text-wrapper-5">
                                    {modalDictionary['MODAL-title22']}
                                  </span>
                                </p>
                              </div>
                              <div
                                className="input-2"
                                style={{
                                  width:
                                    screenWidth >= 1037
                                      ? '507px'
                                      : screenWidth >= 564 && screenWidth < 1037
                                        ? '523px'
                                        : undefined
                                }}
                              >
                                <div
                                  className="overlap-group-5"
                                  style={{
                                    width:
                                      screenWidth >= 1037
                                        ? '509px'
                                        : screenWidth >= 564 && screenWidth < 1037
                                          ? '525px'
                                          : undefined
                                  }}
                                >
                                  <div
                                    className="field-2"
                                    style={{
                                      width:
                                        screenWidth >= 564 && screenWidth < 1037
                                          ? '525px'
                                          : screenWidth >= 1037
                                            ? '509px'
                                            : undefined
                                    }}
                                  >
                                    <input
                                      type="text"
                                      placeholder="Enter an amount"
                                      className="mod-inside-input"
                                      value={depositCryptoFormData.amount}
                                      onChange={(e) => {
                                        100000000000 > +e.target.value &&
                                          setDepositCryptoFormData({
                                            ...depositCryptoFormData,
                                            amount: e.target.value
                                          });

                                        100000000000 > +e.target.value &&
                                          setDepositWireFormData({
                                            ...depositWireFormData,
                                            amount: e.target.value
                                          });
                                      }}
                                    />
                                  </div>

                                  {screenWidth >= 1037 && (
                                    <Flex
                                      width="fit-content"
                                      y="center"
                                      x="end"
                                      gap={0.5}
                                    >
                                      <div
                                        className="tooltip-info tooltip-info-normal"
                                        style={{ right: 0, top: '10px' }}
                                      >
                                        <div
                                          className="tooltip-info-icon"
                                          style={{
                                            height: 26
                                          }}
                                        >
                                          <img
                                            className="info-2"
                                            alt="Info"
                                            src={infoIcon}
                                          />
                                        </div>
                                        <div
                                          className="tooltip-info-text"
                                          style={{ top: '30px' }}
                                        >
                                          <span>
                                            {modalDictionary['MODAL-title48']}
                                          </span>
                                        </div>
                                      </div>
                                    </Flex>
                                  )}

                                  {screenWidth >= 564 && screenWidth < 1037 && (
                                    <Flex
                                      width="fit-content"
                                      y="center"
                                      x="end"
                                      gap={0.5}
                                    >
                                      <div
                                        className="tooltip-info tooltip-info-normal"
                                        style={{ right: 0, top: '10px' }}
                                      >
                                        <div
                                          className="tooltip-info-icon"
                                          style={{
                                            height: 26
                                          }}
                                        >
                                          <div className="info-3">
                                            <div className="overlap-group-6">
                                              <img
                                                className="ellipse"
                                                alt="Ellipse"
                                                src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/ellipse-93-2.svg"
                                              />
                                              <img
                                                className="vector"
                                                alt="Vector"
                                                src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/vector-123-2.svg"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="tooltip-info-text"
                                          style={{ top: '30px' }}
                                        >
                                          <span>
                                            {modalDictionary['MODAL-title23']}
                                          </span>
                                        </div>
                                      </div>
                                    </Flex>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div
                              className="tx-input-2"
                              style={{
                                width:
                                  screenWidth >= 1037
                                    ? '507px'
                                    : screenWidth >= 564 && screenWidth < 1037
                                      ? '523px'
                                      : undefined
                              }}
                            >
                              <div
                                className="overlap-4"
                                style={{
                                  width:
                                    screenWidth >= 1037
                                      ? '509px'
                                      : screenWidth >= 564 && screenWidth < 1037
                                        ? '525px'
                                        : undefined
                                }}
                              >
                                <div
                                  className="field-3"
                                  style={{
                                    width:
                                      screenWidth >= 564 && screenWidth < 1037
                                        ? '525px'
                                        : screenWidth >= 1037
                                          ? '509px'
                                          : undefined
                                  }}
                                >
                                  <input
                                    type="text"
                                    placeholder="Tx Hash"
                                    className="mod-inside-input new-modal-input"
                                    value={depositCryptoFormData.transaction_hash}
                                    onChange={(e) => {
                                      setDepositCryptoFormData({
                                        ...depositCryptoFormData,
                                        transaction_hash: e.target.value.trim()
                                      });
                                    }}
                                  />
                                </div>
                                {screenWidth >= 1037 && (
                                  <Flex
                                    width="fit-content"
                                    y="center"
                                    x="end"
                                    gap={0.5}
                                  >
                                    <div
                                      className="tooltip-info tooltip-info-normal"
                                      style={{ right: 0, top: '10px' }}
                                    >
                                      <div
                                        className="tooltip-info-icon"
                                        style={{
                                          height: 26
                                        }}
                                      >
                                        <img
                                          className="info-2"
                                          alt="Info"
                                          src={infoIcon}
                                        />
                                      </div>
                                      <div
                                        className="tooltip-info-text"
                                        style={{ top: '30px' }}
                                      >
                                        <span>
                                          {modalDictionary['MODAL-title47']}
                                        </span>
                                      </div>
                                    </div>
                                  </Flex>
                                )}

                                {screenWidth >= 564 && screenWidth < 1037 && (
                                  <Flex
                                    width="fit-content"
                                    y="center"
                                    x="end"
                                    gap={0.5}
                                  >
                                    <div
                                      className="tooltip-info tooltip-info-normal"
                                      style={{ right: 0, top: '10px' }}
                                    >
                                      <div
                                        className="tooltip-info-icon"
                                        style={{
                                          height: 26
                                        }}
                                      >
                                        <div className="info-3">
                                          <div className="overlap-group-6">
                                            <img
                                              className="ellipse"
                                              alt="Ellipse"
                                              src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/ellipse-93-3.svg"
                                            />
                                            <img
                                              className="vector"
                                              alt="Vector"
                                              src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/vector-123-3.svg"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="tooltip-info-text"
                                        style={{ top: '30px' }}
                                      >
                                        <span>
                                          {modalDictionary['MODAL-title23']}
                                        </span>
                                      </div>
                                    </div>
                                  </Flex>
                                )}
                              </div>
                            </div>
                            <div
                              className="date-input-2"
                              style={{
                                width:
                                  screenWidth >= 1037
                                    ? '509px'
                                    : screenWidth >= 564 && screenWidth < 1037
                                      ? '525px'
                                      : undefined
                              }}
                            >
                              <div
                                className="overlap-5 overflow-hidden"
                                style={{ overflow: 'unset' }}
                              >
                                <div
                                  className="field-4"
                                  style={{
                                    width:
                                      screenWidth >= 1037
                                        ? '509px'
                                        : screenWidth >= 564 && screenWidth < 1037
                                          ? '525px'
                                          : undefined
                                  }}
                                >
                                  <div
                                    className="value-5"
                                    style={{
                                      width:
                                        screenWidth >= 1037
                                          ? '475px'
                                          : screenWidth >= 564 &&
                                            screenWidth < 1037
                                            ? '491px'
                                            : undefined
                                    }}
                                  >
                                    {modalDictionary['MODAL-title24']}
                                  </div>
                                </div>
                                <div
                                  className="text-wrapper-16"
                                  style={{
                                    position: 'absolute',
                                    right: '0',
                                    top: '0.15rem'
                                  }}
                                >
                                  <Flex y="center" gap="1" marginB={0.8}>
                                    <DatePicker
                                      className="appf-form-input"
                                      selected={datePickerCryptoTransfer}
                                      onChange={(date: any): any => {
                                        setDatePickerCryptoTransfer(date);
                                        setDepositCryptoFormData({
                                          ...depositCryptoFormData,
                                          expected_date: +new Date(date)
                                        });
                                        setDepositCryptoFormData({
                                          ...depositCryptoFormData,
                                          transfer_date: +new Date(date)
                                        });
                                      }}
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      showTimeSelect
                                      dateFormat="MMMM d, yyyy h:mm aa"
                                    />
                                  </Flex>
                                </div>
                              </div>
                            </div>
                            <div
                              className="wallet-input-2"
                              style={{
                                display:
                                  screenWidth >= 1037
                                    ? 'inline-flex'
                                    : screenWidth >= 564 && screenWidth < 1037
                                      ? 'flex'
                                      : undefined,
                                width:
                                  screenWidth >= 564 && screenWidth < 1037
                                    ? '524px'
                                    : undefined
                              }}
                            >
                              <div className="text-wrapper-7 flex items-center">
                                {modalDictionary['MODAL-title24-1']}:
                                <Flex y="center" x="end" gap={0.5}>
                                  <div className="tooltip-info tooltip-info-normal">
                                    <div
                                      className="tooltip-info-icon"
                                      style={{
                                        height: 26
                                      }}
                                    >
                                      <div className="icon !static">
                                        <img
                                          className="img"
                                          alt="Info"
                                          src={
                                            screenWidth >= 1037
                                              ? infoIcon
                                              : screenWidth >= 564 &&
                                                screenWidth < 1037
                                                ? infoIcon
                                                : undefined
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="tooltip-info-text !left-full"
                                      style={{ top: '30px' }}
                                    >
                                      <span>
                                        {modalDictionary['MODAL-title24-1-2']}
                                      </span>
                                    </div>
                                  </div>
                                </Flex>
                              </div>
                              <div
                                className="input-3 flex"
                                style={{
                                  justifyContent: 'between',
                                  width:
                                    screenWidth >= 1037
                                      ? '507px'
                                      : screenWidth >= 564 && screenWidth < 1037
                                        ? '524px'
                                        : undefined
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '1rem',
                                    justifyContent: 'space-between',
                                    width: '100%'
                                  }}
                                >
                                  <select
                                    style={{
                                      background: '#F5F5F5 !important',
                                      border: '0.5px solid #EEEEEE'
                                    }}
                                    className="appf-type-selector"

                                    value={depositCryptoFormData?.saving_balance}
                                    onChange={(e) => {
                                      setDepositCryptoFormData({
                                        ...depositCryptoFormData,
                                        saving_balance: e.target.value
                                      });

                                      setDepositWireFormData({
                                        ...depositWireFormData,
                                        saving_balance: e.target.value
                                      });
                                    }}
                                  >
                                    <option value={''} selected={true}>
                                      Make a selection
                                    </option>
                                    <option value={1}>BOS Treasury | Strategic Diversification *Recommended</option>
                                    <option value={2}>Treasury Balance | 100% Collateralized</option>
                                    <option value={0}>Main Balance</option>
                                  </select>
                                </div>
                              </div>

                              <div className="text-wrapper-7">
                                {modalDictionary['MODAL-title25']}:
                              </div>
                              <div
                                className="input-3"
                                style={{
                                  width:
                                    screenWidth >= 1037
                                      ? '507px'
                                      : screenWidth >= 564 && screenWidth < 1037
                                        ? '524px'
                                        : undefined
                                }}
                              >
                                {isCrypto ? (
                                  <div style={{ display: 'flex', gap: '1rem' }}>
                                    {allAccountsCrypto &&
                                      allAccountsCrypto?.data?.length > 0 && (
                                        <select
                                          style={{
                                            background: '#F5F5F5 !important',
                                            border: '0.5px solid #EEEEEE',
                                            width: '50%'
                                          }}
                                          className="appf-type-selector"

                                          onChange={(e) => {
                                            setSelectedItem(
                                              Number(e.target.value)
                                            );
                                            setDepositCryptoFormData({
                                              ...depositCryptoFormData,
                                              account_id: e.target.value
                                            });
                                            setDepositWireFormData({
                                              ...depositWireFormData,
                                              account_id: e.target.value
                                            });
                                          }}
                                        >
                                          <option selected={true}>
                                            {dictionary['MY-WALLET-title33']}
                                          </option>
                                          {allAccountsCrypto?.data?.map(
                                            (
                                              type: {
                                                id: number;
                                                account_name: string;
                                              },
                                              index: number
                                            ) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={type.id}
                                                >
                                                  {type.account_name}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>
                                      )}

                                    {allAccountsCrypto &&
                                      allAccountsCrypto?.data?.length > 0 && (
                                        <select
                                          style={{
                                            background: '#F5F5F5 !important',
                                            border: '0.5px solid #EEEEEE',
                                            width: '50%'
                                          }}
                                          className="appf-type-selector"

                                          onChange={(e) => {
                                            setDepositCryptoFormData({
                                              ...depositCryptoFormData,
                                              token: e.target.value
                                            });
                                          }}
                                        >
                                          <option value={0} selected={true}>
                                            {modalDictionary['MODAL-title26']}
                                          </option>
                                          <option value={1}>USDT</option>
                                          <option value={2}>USDC</option>
                                        </select>
                                      )}
                                  </div>
                                ) : (
                                  <>
                                    {allAccountsWire &&
                                      allAccountsWire?.data?.length > 0 && (
                                        <select
                                          style={{
                                            background: '#F5F5F5 !important',
                                            border: '0.5px solid #EEEEEE'
                                          }}
                                          className="appf-type-selector"

                                          onChange={(e) => {
                                            setSelectedItem(
                                              Number(e.target.value)
                                            );
                                            setDepositCryptoFormData({
                                              ...depositCryptoFormData,
                                              account_id: e.target.value
                                            });
                                            setDepositWireFormData({
                                              ...depositWireFormData,
                                              account_id: e.target.value
                                            });
                                          }}
                                        >
                                          <option selected={true}>
                                            {dictionary['MY-WALLET-title33']}
                                          </option>
                                          {allAccountsWire?.data?.map(
                                            (
                                              type: {
                                                id: number;
                                                account_name: string;
                                              },
                                              index: number
                                            ) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={type.id}
                                                >
                                                  {type.account_name}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>
                                      )}
                                  </>
                                )}
                                {isCrypto ? (
                                  <>
                                    {allAccountsCrypto &&
                                      allAccountsCrypto?.data?.length === 0 && (
                                        <div className="warning-message-deposit">
                                          <p>{dictionary['MY-WALLET-title42']}</p>
                                          <p>
                                            <b>
                                              {dictionary['MY-WALLET-title43']}
                                            </b>{' '}
                                            {'->'}{' '}
                                            <Link to="/financial-account">
                                              <b className="link">
                                                {dictionary['MY-WALLET-title44']}
                                              </b>
                                            </Link>
                                          </p>
                                        </div>
                                      )}
                                  </>
                                ) : (
                                  <>
                                    {allAccountsWire &&
                                      allAccountsWire?.data?.length === 0 && (
                                        <div className="warning-message-deposit">
                                          <p>{dictionary['MY-WALLET-title58']}</p>
                                          <p>
                                            <b>
                                              {dictionary['MY-WALLET-title59']}
                                            </b>{' '}
                                            {'->'}{' '}
                                            <Link to="/financial-account">
                                              <b className="link">
                                                {dictionary['MY-WALLET-title60']}
                                              </b>
                                            </Link>
                                          </p>
                                        </div>
                                      )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            className="details-2"
                            style={{
                              width:
                                screenWidth >= 1037
                                  ? '499px'
                                  : screenWidth >= 564 && screenWidth < 1037
                                    ? '515px'
                                    : undefined
                            }}
                          >
                            <div className="detail-2">
                              <div className="text-wrapper-8">BOS Balance</div>
                              <div className="text-wrapper-9">
                                {balancesNew?.main_balance ||
                                  balancesNew?.bos_interest ||
                                  balancesNew?.main_balance === 0 ||
                                  balancesNew?.bos_interest === 0 ? (
                                  `$${numberWithCommas(
                                    Number(balancesNew?.main_balance) +
                                    Number(balancesNew?.bos_interest),
                                    2
                                  )}`
                                ) : (
                                  <div className="skeleton-element-item skeleton-box"></div>
                                )}
                              </div>
                            </div>
                            <div className="detail-2">
                              <div className="title-3">
                                <div className="value-2">
                                  {modalDictionary['MODAL-title28']}
                                </div>
                                <Flex
                                  width="fit-content"
                                  y="center"
                                  x="end"
                                  gap={0.5}
                                >
                                  <div
                                    className="tooltip-info tooltip-info-normal"
                                    style={{ right: 0, top: '10px' }}
                                  >
                                    <div
                                      className="tooltip-info-icon"
                                      style={{
                                        height: 26
                                      }}
                                    >
                                      <div className="icon">
                                        <img
                                          className="img"
                                          alt="Info"
                                          src={
                                            screenWidth >= 1037
                                              ? infoIcon
                                              : screenWidth >= 564 &&
                                                screenWidth < 1037
                                                ? infoIcon
                                                : undefined
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="tooltip-info-text"
                                      style={{ top: '30px' }}
                                    >
                                      <span>
                                        {modalDictionary['MODAL-title23']}
                                      </span>
                                    </div>
                                  </div>
                                </Flex>
                              </div>
                              <div className="text-wrapper-9">
                                {depositCryptoFormData.saving_balance == 0 && (
                                  <>{(depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee)}%</>
                                )}
                                {depositCryptoFormData.saving_balance == 1 && (
                                  <>
                                    {(depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee)}% /{' '}
                                    {settings?.stake_deposit_fee}%
                                  </>
                                )}
                                {depositCryptoFormData.saving_balance == 2 && (
                                  <>{settings?.stake_deposit_fee}%</>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {/* Bank */}
                          <div
                            className="overlap-group-wrapper bank"
                            style={{
                              left:
                                screenWidth >= 1037
                                  ? '0'
                                  : screenWidth >= 564 && screenWidth < 1037
                                    ? '1px'
                                    : undefined,
                              width:
                                screenWidth >= 1037
                                  ? '509px'
                                  : screenWidth >= 564 && screenWidth < 1037
                                    ? '525px'
                                    : undefined,
                              display: 'grid',
                              gridTemplateColumns: '1fr 1fr',
                              gap: '2rem'
                            }}
                          >
                            <div
                              className="overlap-group-4 bank"
                              style={{
                                minWidth: 180,

                                width:
                                  screenWidth >= 1037
                                    ? '287px'
                                    : screenWidth >= 564 && screenWidth < 1037
                                      ? '100%'
                                      : undefined
                              }}
                            >
                              <textarea
                                name=""
                                id=""
                                className="mod-inside-input"
                                style={{ height: '100%' }}
                                placeholder="Description"
                                cols={30}
                                rows={2}
                                value={depositWireFormData.request_description}
                                onChange={(e) =>
                                  setDepositWireFormData({
                                    ...depositWireFormData,
                                    request_description: e.target.value
                                  })
                                }
                              />
                            </div>
                            <div className="mod-upload">
                              <Flex direction={'col'} gap={0.7} y={'center'}>
                                <div>
                                  <label
                                    className="mod-upload-label"
                                    style={{
                                      fontSize: 14,
                                      color: '#153458'
                                    }}
                                  >
                                    {dictionary['MY-WALLET-title61']}:
                                  </label>
                                  <input
                                    type="file"
                                    id="file-input"
                                    name="file-input"
                                    onChange={(e) => {
                                      setDepositWireFormData({
                                        ...depositWireFormData,
                                        transaction_document: e.target.files?.[0]
                                      });
                                    }}
                                  />
                                </div>
                                {/* Next */}
                                <div>
                                  <label
                                    className="mod-upload-label"
                                    style={{
                                      fontSize: 14,
                                      color: '#153458'
                                    }}
                                  >
                                    {dictionary['MY-WALLET-title131']}:
                                  </label>
                                  <input
                                    type="file"
                                    id="file-input"
                                    name="file-input"
                                    onChange={(e) => {
                                      setDepositWireFormData({
                                        ...depositWireFormData,
                                        transaction_document_2:
                                          e.target.files?.[0]
                                      });
                                    }}
                                  />
                                </div>
                                {/* Next */}
                                <div>
                                  <label
                                    className="mod-upload-label"
                                    style={{
                                      fontSize: 14,
                                      color: '#153458'
                                    }}
                                  >
                                    {dictionary['MY-WALLET-title132']}:
                                  </label>
                                  <input
                                    type="file"
                                    id="file-input"
                                    name="file-input"
                                    onChange={(e) => {
                                      setDepositWireFormData({
                                        ...depositWireFormData,
                                        transaction_document_3:
                                          e.target.files?.[0]
                                      });
                                    }}
                                  />
                                </div>
                              </Flex>
                            </div>
                          </div>
                          <div
                            className="overlap-wrapper bank"
                            style={{
                              left:
                                screenWidth >= 1037
                                  ? '0'
                                  : screenWidth >= 564 && screenWidth < 1037
                                    ? '1px'
                                    : undefined,
                              width:
                                screenWidth >= 1037
                                  ? '509px'
                                  : screenWidth >= 564 && screenWidth < 1037
                                    ? '525px'
                                    : undefined
                            }}
                          >
                            <div
                              className="note-the-minimum-wrapper"
                              style={{
                                backgroundImage:
                                  screenWidth >= 1037
                                    ? 'url(https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/bg-3.svg)'
                                    : screenWidth >= 564 && screenWidth < 1037
                                      ? 'url(https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/bg-5.svg)'
                                      : undefined,
                                width:
                                  screenWidth >= 1037
                                    ? '507px'
                                    : screenWidth >= 564 && screenWidth < 1037
                                      ? '523px'
                                      : undefined
                              }}
                            >
                              <p
                                className="note-the-minimum-2"
                                style={{
                                  left:
                                    screenWidth >= 1037
                                      ? '41px'
                                      : screenWidth >= 564 && screenWidth < 1037
                                        ? '42px'
                                        : undefined,
                                  whiteSpace:
                                    screenWidth >= 1037 ? 'nowrap' : undefined,
                                  width:
                                    screenWidth >= 564 && screenWidth < 1037
                                      ? '439px'
                                      : undefined
                                }}
                              >
                                <span className="span">
                                  {modalDictionary['MODAL-title19']}
                                </span>
                                <span className="text-wrapper-2">
                                  {' '}
                                  The minimum deposit for your{' '}
                                  {depositCryptoFormData.saving_balance == 1 ||
                                    depositCryptoFormData.saving_balance == 2
                                    ? 'Treasury Balance'
                                    : 'BOS Main Balance'}{' '}
                                  is $
                                  {depositCryptoFormData.saving_balance == 0
                                    ? settings?.minimum_deposit
                                    : depositCryptoFormData.saving_balance == 1
                                      ? calculateFullAmount(
                                        settings?.minimum_deposit_stake,
                                        settings?.split_percentage_stake
                                      )
                                      : settings?.minimum_deposit_stake}
                                  {modalDictionary['MODAL-title21']}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div
                            className="inputs-2 inputs-2-b"
                            style={{
                              width:
                                screenWidth >= 1037
                                  ? '507px'
                                  : screenWidth >= 564 && screenWidth < 1037
                                    ? '524px'
                                    : undefined
                            }}
                          >
                            <div
                              className="amount-input-2"
                              style={{
                                width:
                                  screenWidth >= 1037
                                    ? '507px'
                                    : screenWidth >= 564 && screenWidth < 1037
                                      ? '523px'
                                      : undefined
                              }}
                            >
                              <div
                                className="min-max-2"
                                style={{
                                  width:
                                    screenWidth >= 1037
                                      ? '484px'
                                      : screenWidth >= 564 && screenWidth < 1037
                                        ? '500px'
                                        : undefined
                                }}
                              >
                                <p className="min pointer">
                                  <span
                                    onClick={(e) => {
                                      if (
                                        depositCryptoFormData.saving_balance == 1
                                      ) {
                                        setDepositCryptoFormData({
                                          ...depositCryptoFormData,
                                          amount: Number(
                                            calculateFullAmount(
                                              settings?.minimum_deposit_stake,
                                              settings?.split_percentage_stake
                                            )
                                          )
                                        });
                                        setDepositWireFormData({
                                          ...depositWireFormData,
                                          amount: Number(
                                            calculateFullAmount(
                                              settings?.minimum_deposit_stake,
                                              settings?.split_percentage_stake
                                            )
                                          )
                                        });
                                      } else if (
                                        depositCryptoFormData.saving_balance == 0
                                      ) {
                                        setDepositCryptoFormData({
                                          ...depositCryptoFormData,
                                          amount: Number(
                                            settings?.minimum_deposit
                                          )
                                        });
                                        setDepositWireFormData({
                                          ...depositWireFormData,
                                          amount: Number(
                                            settings?.minimum_deposit
                                          )
                                        });
                                      } else {
                                        setDepositWireFormData({
                                          ...depositWireFormData,
                                          amount: Number(
                                            settings?.minimum_deposit_stake
                                          )
                                        });
                                        setDepositCryptoFormData({
                                          ...depositCryptoFormData,
                                          amount: Number(
                                            settings?.minimum_deposit_stake
                                          )
                                        });
                                      }
                                    }}
                                  >
                                    <span className="text-wrapper-3">
                                      {modalDictionary['MODAL-title4']}:
                                    </span>
                                    <span className="text-wrapper-4">&nbsp;</span>
                                    <span className="text-wrapper-5">
                                      $
                                      {settings
                                        ? depositCryptoFormData.saving_balance ==
                                          1
                                          ? calculateFullAmount(
                                            settings?.minimum_deposit_stake,
                                            settings?.split_percentage_stake
                                          )
                                          : depositCryptoFormData.saving_balance ==
                                            0
                                            ? settings?.minimum_deposit
                                            : settings?.minimum_deposit_stake
                                        : '0'}
                                    </span>
                                  </span>

                                  {/* {depositCryptoFormData.saving_balance == 1 ? (
                                  <>
                                    <span
                                      className="text-black ml-4"
                                      onClick={(e) => {
                                        if (
                                          depositCryptoFormData.saving_balance ==
                                          0
                                        ) {
                                          setDepositCryptoFormData({
                                            ...depositCryptoFormData,
                                            amount: Number(
                                              calculateFullAmount(
                                                settings?.minimum_deposit_stake,
                                                settings?.split_percentage_stake
                                              )
                                            )
                                          });
                                          setDepositWireFormData({
                                            ...depositWireFormData,
                                            amount: Number(
                                              calculateFullAmount(
                                                settings?.minimum_deposit_stake,
                                                settings?.split_percentage_stake
                                              )
                                            )
                                          });
                                        } else {
                                          setDepositCryptoFormData({
                                            ...depositCryptoFormData,
                                            amount: Number(
                                              calculateFullAmount(
                                                settings?.minimum_deposit_stake,
                                                settings?.split_percentage_stake
                                              )
                                            )
                                          });
                                          setDepositWireFormData({
                                            ...depositWireFormData,
                                            amount: Number(
                                              calculateFullAmount(
                                                settings?.minimum_deposit_stake,
                                                settings?.split_percentage_stake
                                              )
                                            )
                                          });
                                        }
                                      }}
                                    >
                                      (Required Amount: $
                                      {calculateFullAmount(
                                        settings?.minimum_deposit_stake,
                                        settings?.split_percentage_stake
                                      )}
                                      )
                                    </span>
                                  </>
                                ) : null} */}
                                </p>
                                <p className="max-unlimited">
                                  <span className="text-wrapper-3">
                                    {modalDictionary['MODAL-title5']}:
                                  </span>
                                  <span className="text-wrapper-4">&nbsp;</span>
                                  <span className="text-wrapper-5">
                                    {modalDictionary['MODAL-title22']}
                                  </span>
                                </p>
                              </div>
                              <div
                                className="input-2"
                                style={{
                                  width:
                                    screenWidth >= 1037
                                      ? '507px'
                                      : screenWidth >= 564 && screenWidth < 1037
                                        ? '523px'
                                        : undefined
                                }}
                              >
                                <div
                                  className="overlap-group-5"
                                  style={{
                                    width:
                                      screenWidth >= 1037
                                        ? '509px'
                                        : screenWidth >= 564 && screenWidth < 1037
                                          ? '525px'
                                          : undefined
                                  }}
                                >
                                  <div
                                    className="field-2"
                                    style={{
                                      width:
                                        screenWidth >= 564 && screenWidth < 1037
                                          ? '525px'
                                          : screenWidth >= 1037
                                            ? '509px'
                                            : undefined
                                    }}
                                  >
                                    <input
                                      type="text"
                                      placeholder="Enter an amount"
                                      className="mod-inside-input"
                                      value={depositCryptoFormData.amount}
                                      onChange={(e) => {
                                        100000000000 > +e.target.value &&
                                          setDepositCryptoFormData({
                                            ...depositCryptoFormData,
                                            amount: e.target.value
                                          });

                                        100000000000 > +e.target.value &&
                                          setDepositWireFormData({
                                            ...depositWireFormData,
                                            amount: e.target.value
                                          });
                                      }}
                                    />
                                  </div>
                                  {screenWidth >= 1037 && (
                                    <Flex
                                      width="fit-content"
                                      y="center"
                                      x="end"
                                      gap={0.5}
                                    >
                                      <div
                                        className="tooltip-info tooltip-info-normal"
                                        style={{ right: 0, top: '10px' }}
                                      >
                                        <div
                                          className="tooltip-info-icon"
                                          style={{
                                            height: 26
                                          }}
                                        >
                                          <img
                                            className="info-2"
                                            alt="Info"
                                            src={infoIcon}
                                          />
                                        </div>
                                        <div
                                          className="tooltip-info-text"
                                          style={{ top: '30px' }}
                                        >
                                          <span>
                                            {modalDictionary['MODAL-title48']}
                                          </span>
                                        </div>
                                      </div>
                                    </Flex>
                                  )}

                                  {screenWidth >= 564 && screenWidth < 1037 && (
                                    <Flex
                                      width="fit-content"
                                      y="center"
                                      x="end"
                                      gap={0.5}
                                    >
                                      <div
                                        className="tooltip-info tooltip-info-normal"
                                        style={{ right: 0, top: '10px' }}
                                      >
                                        <div
                                          className="tooltip-info-icon"
                                          style={{
                                            height: 26
                                          }}
                                        >
                                          <div className="info-3">
                                            <div className="overlap-group-6">
                                              <img
                                                className="ellipse"
                                                alt="Ellipse"
                                                src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/ellipse-93-2.svg"
                                              />
                                              <img
                                                className="vector"
                                                alt="Vector"
                                                src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/vector-123-2.svg"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="tooltip-info-text"
                                          style={{ top: '30px' }}
                                        >
                                          <span>
                                            {modalDictionary['MODAL-title23']}
                                          </span>
                                        </div>
                                      </div>
                                    </Flex>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div
                              className="date-input-2"
                              style={{
                                width:
                                  screenWidth >= 1037
                                    ? '509px'
                                    : screenWidth >= 564 && screenWidth < 1037
                                      ? '525px'
                                      : undefined
                              }}
                            >
                              <div
                                className="overlap-5 overflow-hidden"
                                style={{ overflow: 'unset' }}
                              >
                                <div
                                  className="field-4"
                                  style={{
                                    width:
                                      screenWidth >= 1037
                                        ? '509px'
                                        : screenWidth >= 564 && screenWidth < 1037
                                          ? '525px'
                                          : undefined
                                  }}
                                >
                                  <div className="value-5">
                                    {modalDictionary['MODAL-title27']}
                                  </div>
                                </div>
                                <div
                                  className="text-wrapper-16"
                                  style={{
                                    position: 'absolute',
                                    right: '0',
                                    top: '0.15rem'
                                  }}
                                >
                                  <DickPickerInputWireTransfer />
                                </div>
                              </div>
                            </div>
                            <div
                              className="wallet-input-2"
                              style={{
                                display:
                                  screenWidth >= 1037
                                    ? 'inline-flex'
                                    : screenWidth >= 564 && screenWidth < 1037
                                      ? 'flex'
                                      : undefined,
                                width:
                                  screenWidth >= 564 && screenWidth < 1037
                                    ? '524px'
                                    : undefined
                              }}
                            >
                              <div className="text-wrapper-7 flex items-center">
                                {modalDictionary['MODAL-title24-1']}:
                                <Flex y="center" x="end" gap={0.5}>
                                  <div className="tooltip-info tooltip-info-normal">
                                    <div
                                      className="tooltip-info-icon"
                                      style={{
                                        height: 26
                                      }}
                                    >
                                      <div className="icon !static">
                                        <img
                                          className="img"
                                          alt="Info"
                                          src={
                                            screenWidth >= 1037
                                              ? infoIcon
                                              : screenWidth >= 564 &&
                                                screenWidth < 1037
                                                ? infoIcon
                                                : undefined
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="tooltip-info-text !left-full"
                                      style={{ top: '30px' }}
                                    >
                                      <span>
                                        {modalDictionary['MODAL-title24-1-2']}
                                      </span>
                                    </div>
                                  </div>
                                </Flex>
                              </div>
                              <div
                                className="input-3"
                                style={{
                                  width:
                                    screenWidth >= 1037
                                      ? '507px'
                                      : screenWidth >= 564 && screenWidth < 1037
                                        ? '524px'
                                        : undefined
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '1rem',
                                    justifyContent: 'space-between',
                                    width: '100%'
                                  }}
                                >
                                  <select
                                    style={{
                                      background: '#F5F5F5 !important',
                                      border: '0.5px solid #EEEEEE'
                                    }}
                                    className="appf-type-selector"

                                    value={depositCryptoFormData?.saving_balance}
                                    onChange={(e) => {
                                      setDepositCryptoFormData({
                                        ...depositCryptoFormData,
                                        saving_balance: e.target.value
                                      });

                                      setDepositWireFormData({
                                        ...depositWireFormData,
                                        saving_balance: e.target.value
                                      });
                                    }}
                                  >
                                    <option value={''}>
                                      Make a selection
                                    </option>
                                    <option value={2}>
                                      Treasury Balance | 100% Collateralized
                                    </option>
                                    <option value={1}>
                                      BOS Treasury | Strategic Diversification *Recommended
                                    </option>
                                    <option value={0}>Main Balance</option>
                                  </select>
                                </div>
                              </div>

                              <div className="text-wrapper-7">
                                {modalDictionary['MODAL-title25']}:
                              </div>
                              <div
                                className="input-3"
                                style={{
                                  width:
                                    screenWidth >= 1037
                                      ? '507px'
                                      : screenWidth >= 564 && screenWidth < 1037
                                        ? '524px'
                                        : undefined
                                }}
                              >
                                {isCrypto ? (
                                  <div style={{ display: 'flex', gap: '1rem' }}>
                                    {allAccountsCrypto &&
                                      allAccountsCrypto?.data?.length > 0 && (
                                        <select
                                          style={{
                                            background: '#F5F5F5 !important',
                                            border: '0.5px solid #EEEEEE',
                                            width: '50%'
                                          }}
                                          className="appf-type-selector"

                                          onChange={(e) => {
                                            setSelectedItem(
                                              Number(e.target.value)
                                            );
                                            setDepositCryptoFormData({
                                              ...depositCryptoFormData,
                                              account_id: e.target.value
                                            });
                                            setDepositWireFormData({
                                              ...depositWireFormData,
                                              account_id: e.target.value
                                            });
                                          }}
                                        >
                                          <option selected={true}>
                                            {dictionary['MY-WALLET-title33']}
                                          </option>
                                          {allAccountsCrypto?.data?.map(
                                            (
                                              type: {
                                                id: number;
                                                account_name: string;
                                              },
                                              index: number
                                            ) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={type.id}
                                                >
                                                  {type.account_name}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>
                                      )}

                                    {allAccountsCrypto &&
                                      allAccountsCrypto?.data?.length > 0 && (
                                        <select
                                          style={{
                                            background: '#F5F5F5 !important',
                                            border: '0.5px solid #EEEEEE',
                                            width: '50%'
                                          }}
                                          className="appf-type-selector"

                                          onChange={(e) => {
                                            setDepositCryptoFormData({
                                              ...depositCryptoFormData,
                                              token: e.target.value
                                            });
                                          }}
                                        >
                                          <option value={0} selected={true}>
                                            {modalDictionary['MODAL-title26']}
                                          </option>
                                          <option value={1}>USDT</option>
                                          <option value={2}>USDC</option>
                                        </select>
                                      )}
                                  </div>
                                ) : (
                                  <>
                                    <div style={{ display: 'flex', gap: '1rem' }}>
                                      {allAccountsWire &&
                                        allAccountsWire?.data?.length > 0 && (
                                          <select
                                            style={{
                                              background: '#F5F5F5 !important',
                                              border: '0.5px solid #EEEEEE',
                                              width: '50%'
                                            }}
                                            className="appf-type-selector"

                                            onChange={(e) => {
                                              setSelectedItem(
                                                Number(e.target.value)
                                              );
                                              setDepositCryptoFormData({
                                                ...depositCryptoFormData,
                                                account_id: e.target.value
                                              });
                                              setDepositWireFormData({
                                                ...depositWireFormData,
                                                account_id: e.target.value
                                              });
                                            }}
                                          >
                                            <option selected={true}>
                                              {dictionary['MY-WALLET-title33']}
                                            </option>
                                            {allAccountsWire?.data?.map(
                                              (
                                                type: {
                                                  id: number;
                                                  account_name: string;
                                                },
                                                index: number
                                              ) => {
                                                return (
                                                  <option
                                                    key={index}
                                                    value={type.id}
                                                  >
                                                    {type.account_name}
                                                  </option>
                                                );
                                              }
                                            )}
                                          </select>
                                        )}

                                      <select
                                        style={{
                                          background: '#F5F5F5 !important',
                                          border: '0.5px solid #EEEEEE',
                                          width: '50%'
                                        }}
                                        className="appf-type-selector"
                                        onChange={(e) => {
                                          setDepositWireFormData({
                                            ...depositWireFormData,
                                            bank_type: e.target.value
                                          });
                                        }}
                                        value={depositWireFormData?.bank_type}
                                      >
                                        {/* <option value={0} selected={true}>
                                          {modalDictionary['MODAL-title51']}
                                        </option>
                                        <option value={1}>IMBL</option> */}
                                        {/* <option value={2}>TCC</option> */}
                                        {/* <option value={3}>Caye Bank</option> */}
                                        <option value={4} selected={true}>Chase Bank</option>
                                      </select>
                                    </div>
                                  </>
                                )}
                                {isCrypto ? (
                                  <>
                                    {allAccountsCrypto &&
                                      allAccountsCrypto?.data?.length === 0 && (
                                        <div className="warning-message-deposit">
                                          <p>{dictionary['MY-WALLET-title42']}</p>
                                          <p>
                                            <b>
                                              {dictionary['MY-WALLET-title43']}
                                            </b>{' '}
                                            {'->'}{' '}
                                            <Link to="/financial-account">
                                              <b className="link">
                                                {dictionary['MY-WALLET-title44']}
                                              </b>
                                            </Link>
                                          </p>
                                        </div>
                                      )}
                                  </>
                                ) : (
                                  <>
                                    {allAccountsWire &&
                                      allAccountsWire?.data?.length === 0 && (
                                        <div className="warning-message-deposit">
                                          <p>{dictionary['MY-WALLET-title58']}</p>
                                          <p>
                                            <b>
                                              {dictionary['MY-WALLET-title59']}
                                            </b>{' '}
                                            {'->'}{' '}
                                            <Link to="/financial-account">
                                              <b className="link">
                                                {dictionary['MY-WALLET-title60']}
                                              </b>
                                            </Link>
                                          </p>
                                        </div>
                                      )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            className="details-2"
                            style={{
                              width:
                                screenWidth >= 1037
                                  ? '499px'
                                  : screenWidth >= 564 && screenWidth < 1037
                                    ? '515px'
                                    : undefined
                            }}
                          >
                            <div className="detail-2">
                              <div className="text-wrapper-8">
                                {modalDictionary['MODAL-title2']}
                              </div>
                              <div className="text-wrapper-9">
                                {balancesNew?.main_balance ||
                                  balancesNew?.bos_interest ||
                                  balancesNew?.main_balance === 0 ||
                                  balancesNew?.bos_interest === 0 ? (
                                  `$${numberWithCommas(
                                    Number(balancesNew?.main_balance) +
                                    Number(balancesNew?.bos_interest),
                                    2
                                  )}`
                                ) : (
                                  <div className="skeleton-element-item skeleton-box"></div>
                                )}
                              </div>
                            </div>
                            <div className="detail-2">
                              <div className="title-3">
                                <div className="value-2">
                                  {modalDictionary['MODAL-title28']}
                                </div>
                                <Flex
                                  width="fit-content"
                                  y="center"
                                  x="end"
                                  gap={0.5}
                                >
                                  <div
                                    className="tooltip-info tooltip-info-normal"
                                    style={{ right: 0, top: '10px' }}
                                  >
                                    <div
                                      className="tooltip-info-icon"
                                      style={{
                                        height: 26
                                      }}
                                    >
                                      <div className="icon">
                                        <img
                                          className="img"
                                          alt="Info"
                                          src={
                                            screenWidth >= 1037
                                              ? infoIcon
                                              : screenWidth >= 564 &&
                                                screenWidth < 1037
                                                ? infoIcon
                                                : undefined
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="tooltip-info-text"
                                      style={{ top: '30px' }}
                                    >
                                      <span>
                                        {modalDictionary['MODAL-title23']}
                                      </span>
                                    </div>
                                  </div>
                                </Flex>
                              </div>
                              <div className="text-wrapper-9">
                                {depositCryptoFormData.saving_balance == 0 && (
                                  <>{(depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee)}%</>
                                )}
                                {depositCryptoFormData.saving_balance == 1 && (
                                  <>
                                    {(depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee)}% /{' '}
                                    {settings?.stake_deposit_fee}%
                                  </>
                                )}
                                {depositCryptoFormData.saving_balance == 2 && (
                                  <>{settings?.stake_deposit_fee}%</>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      <div
                        className="tabs-2"
                        style={{
                          display:
                            screenWidth >= 1037
                              ? 'inline-flex'
                              : screenWidth >= 564 && screenWidth < 1037
                                ? 'flex'
                                : undefined,
                          left:
                            screenWidth >= 1037
                              ? '0'
                              : screenWidth >= 564 && screenWidth < 1037
                                ? '1px'
                                : undefined,
                          width:
                            screenWidth >= 564 && screenWidth < 1037
                              ? '523px'
                              : undefined
                        }}
                      >
                        <div className="tab-bg" />
                        <div className="crypto-tab-2">
                          <div
                            className={
                              isCrypto
                                ? 'overlap-group-7 pointer'
                                : 'overlap-6 pointer'
                            }
                            onClick={() => setIsCrypto(true)}
                          >
                            <div
                              className={
                                isCrypto ? 'text-wrapper-17' : 'text-wrapper-18'
                              }
                            >
                              {modalDictionary['MODAL-title30']}
                            </div>
                          </div>
                        </div>
                        <div className="bank-tab-2">
                          <div
                            className={
                              isCrypto
                                ? 'overlap-6 pointer'
                                : 'overlap-group-7 pointer'
                            }
                            onClick={() => {
                              setIsCrypto(false);
                              setIsShowBankDetails(false);
                            }}
                          >
                            <div
                              className={
                                isCrypto ? 'text-wrapper-18' : 'text-wrapper-17'
                              }
                            >
                              {modalDictionary['MODAL-title31']}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {screenWidth >= 564 &&
                      screenWidth < 1037 &&
                      (isCrypto ? (
                        <>
                          {/* Crypto */}
                          <div className="right-2">
                            <div className="content-4">
                              <div className="wallet-info-2">
                                <div className="qr">
                                  {walletInfoDepositModal ? (
                                    <QRCodeSVG
                                      style={{
                                        background: 'white',
                                        padding: '1rem'
                                      }}
                                      value={
                                        walletInfoDepositModal?.crypto?.[0]
                                          ?.address
                                      }
                                      size={150}
                                      bgColor={'#ffffff'}
                                      fgColor={'#000000'}
                                      level={'L'}
                                      includeMargin={false}
                                    />
                                  ) : (
                                    <img
                                      className="mod-img"
                                      src="/images/QRCode.png"
                                      alt="QRCode"
                                    />
                                  )}
                                  <p className="note-2">
                                    {dictionary['MY-WALLET-title41']}
                                    &nbsp;{' '}
                                    <a
                                      className="bos-call"
                                      href="https://calendly.com/d/ckn7-b7d-24n/bos-deposit-call"
                                      target="_blank"
                                    >
                                      Book a call if you need assistance.
                                    </a>
                                  </p>
                                </div>
                                <img
                                  className="line-3"
                                  alt="Line"
                                  src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/line-5.svg"
                                />
                                <div className="wallet-2">
                                  <div
                                    className="wallet-address-2"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        walletInfoDepositModal?.crypto?.[0]
                                          ?.address
                                    }}
                                  ></div>
                                  <CopyToClipboard
                                    text={
                                      walletInfoDepositModal?.crypto?.[0]?.address
                                    }
                                    onCopy={() => {
                                      toast.success('Copied Successfully.');
                                    }}
                                  >
                                    <div className="copy-btn pointer">
                                      <div className="text">
                                        {modalDictionary['MODAL-title32']}
                                      </div>
                                    </div>
                                  </CopyToClipboard>
                                </div>
                              </div>
                              <div className="result-info-2">
                                <div className="bg-2" />
                                <div className="payment-details-2">
                                  <div className="div-3">
                                    <div className="item">
                                      <div className="text-wrapper-8">
                                        {modalDictionary['MODAL-title7']}
                                      </div>
                                      <div className="text-wrapper-9">
                                        Deposit
                                      </div>
                                    </div>
                                    <div className="item">
                                      <div className="text-wrapper-8">
                                        {modalDictionary['MODAL-title8']}
                                      </div>
                                      <div className="text-wrapper-9">
                                        $
                                        {numberWithCommas(
                                          depositCryptoFormData.amount,
                                          0
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <img
                                    className="line-4"
                                    alt="Line"
                                    src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/line-6.svg"
                                  />
                                  <div className="div-3">
                                    <div className="item">
                                      <div className="title-2">
                                        <div className="text-wrapper-13">
                                          {modalDictionary['MODAL-title28']}
                                        </div>
                                        <Flex
                                          width="fit-content"
                                          y="center"
                                          x="end"
                                          gap={0.5}
                                        >
                                          <div
                                            className="tooltip-info tooltip-info-normal"
                                            style={{ right: 0, top: '10px' }}
                                          >
                                            <div
                                              className="tooltip-info-icon"
                                              style={{
                                                height: 26
                                              }}
                                            >
                                              <div className="info-wrapper">
                                                <img
                                                  className="img"
                                                  alt="Info"
                                                  src={infoIcon}
                                                />
                                              </div>
                                            </div>
                                            <div
                                              className="tooltip-info-text"
                                              style={{ top: '30px' }}
                                            >
                                              <span>
                                                {modalDictionary['MODAL-title23']}
                                              </span>
                                            </div>
                                          </div>
                                        </Flex>
                                      </div>
                                      <div className="text-wrapper-9">
                                        {depositCryptoFormData.saving_balance ==
                                          0 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                depositCryptoFormData.amount *
                                                ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100)
                                              )}
                                            </>
                                          )}
                                        {depositCryptoFormData.saving_balance ==
                                          1 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                depositCryptoFormData.amount *
                                                ((100 -
                                                  Number(
                                                    settings?.split_percentage_stake
                                                  )) /
                                                  100) *
                                                ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100)
                                              )}{' '}
                                              / $
                                              {numberWithCommas(
                                                depositCryptoFormData.amount *
                                                (Number(
                                                  settings?.split_percentage_stake
                                                ) /
                                                  100) *
                                                (settings?.stake_deposit_fee /
                                                  100)
                                              )}
                                            </>
                                          )}
                                        {depositCryptoFormData.saving_balance ==
                                          2 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                depositCryptoFormData.amount *
                                                (settings?.stake_deposit_fee /
                                                  100)
                                              )}
                                            </>
                                          )}
                                      </div>
                                    </div>
                                    <div className="item">
                                      <div className="text-wrapper-8">
                                        {modalDictionary['MODAL-title29']}
                                      </div>
                                      <div className="text-wrapper-9">
                                        {depositCryptoFormData.saving_balance ==
                                          0 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                Number(
                                                  depositCryptoFormData.amount
                                                ) -
                                                Number(
                                                  depositCryptoFormData.amount
                                                ) *
                                                ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100)
                                              )}
                                            </>
                                          )}

                                        {depositCryptoFormData.saving_balance ==
                                          1 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                Number(
                                                  depositCryptoFormData.amount *
                                                  ((100 -
                                                    Number(
                                                      settings?.split_percentage_stake
                                                    )) /
                                                    100)
                                                ) -
                                                Number(
                                                  depositCryptoFormData.amount
                                                ) *
                                                ((100 -
                                                  Number(
                                                    settings?.split_percentage_stake
                                                  )) /
                                                  100) *
                                                ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100)
                                              )}{' '}
                                              / $
                                              {numberWithCommas(
                                                Number(
                                                  depositCryptoFormData.amount *
                                                  (Number(
                                                    settings?.split_percentage_stake
                                                  ) /
                                                    100)
                                                ) -
                                                Number(
                                                  depositCryptoFormData.amount
                                                ) *
                                                (Number(
                                                  settings?.split_percentage_stake
                                                ) /
                                                  100) *
                                                (settings?.stake_deposit_fee /
                                                  100)
                                              )}
                                            </>
                                          )}

                                        {depositCryptoFormData.saving_balance ==
                                          2 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                Number(
                                                  depositCryptoFormData.amount
                                                ) -
                                                Number(
                                                  depositCryptoFormData.amount
                                                ) *
                                                (settings?.stake_deposit_fee /
                                                  100)
                                              )}
                                            </>
                                          )}
                                      </div>
                                    </div>
                                    <div className="item">
                                      <p className="text-wrapper-8 flex gap-x-1 items-center">
                                        {depositCryptoFormData.saving_balance ==
                                          0 && (
                                            <>{modalDictionary['MODAL-title9']}</>
                                          )}
                                        {depositCryptoFormData.saving_balance ==
                                          1 && (
                                            <>
                                              {modalDictionary['MODAL-title9-1']}
                                              <Flex
                                                width="fit-content"
                                                y="center"
                                                x="end"
                                                gap={0.5}
                                              >
                                                <div
                                                  className="tooltip-info tooltip-info-normal"
                                                  style={{ right: 0, top: '10px' }}
                                                >
                                                  <div
                                                    className="tooltip-info-icon"
                                                    style={{
                                                      height: 26
                                                    }}
                                                  >
                                                    <div className="info-wrapper">
                                                      <img
                                                        className="img"
                                                        alt="Info"
                                                        src={infoIcon}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="tooltip-info-text"
                                                    style={{ top: '30px' }}
                                                  >
                                                    <span>
                                                      {
                                                        modalDictionary[
                                                        'MODAL-title9-1-1'
                                                        ]
                                                      }
                                                    </span>
                                                  </div>
                                                </div>
                                              </Flex>
                                            </>
                                          )}
                                        {depositCryptoFormData.saving_balance ==
                                          2 && (
                                            <>{modalDictionary['MODAL-title9-2']}</>
                                          )}
                                      </p>
                                      <div className="text-wrapper-9">
                                        {depositCryptoFormData.saving_balance ==
                                          0 && (
                                            <>
                                              {numberWithCommas(
                                                Number(balancesNew?.main_balance) +
                                                Number(
                                                  balancesNew?.bos_interest
                                                ) +
                                                (Number(
                                                  depositCryptoFormData.amount
                                                ) -
                                                  Number(
                                                    depositCryptoFormData.amount
                                                  ) *
                                                  ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100))
                                              )}
                                            </>
                                          )}
                                        {depositCryptoFormData.saving_balance ==
                                          1 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                Number(balancesNew?.main_balance) +
                                                Number(
                                                  balancesNew?.bos_interest
                                                ) +
                                                (Number(
                                                  depositCryptoFormData.amount *
                                                  ((100 -
                                                    Number(
                                                      settings?.split_percentage_stake
                                                    )) /
                                                    100)
                                                ) -
                                                  depositCryptoFormData.amount *
                                                  ((100 -
                                                    Number(
                                                      settings?.split_percentage_stake
                                                    )) /
                                                    100) *
                                                  ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100))
                                              )}{' '}
                                              / $
                                              {numberWithCommas(
                                                Number(
                                                  balancesNew?.treasury_balance
                                                ) +
                                                (Number(
                                                  depositCryptoFormData.amount *
                                                  (Number(
                                                    settings?.split_percentage_stake
                                                  ) /
                                                    100)
                                                ) -
                                                  depositCryptoFormData.amount *
                                                  (Number(
                                                    settings?.split_percentage_stake
                                                  ) /
                                                    100) *
                                                  (settings?.stake_deposit_fee /
                                                    100))
                                              )}
                                            </>
                                          )}

                                        {depositCryptoFormData.saving_balance ==
                                          2 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                Number(
                                                  balancesNew?.treasury_balance
                                                ) +
                                                (Number(
                                                  depositCryptoFormData.amount
                                                ) -
                                                  Number(
                                                    depositCryptoFormData.amount
                                                  ) *
                                                  (settings?.stake_deposit_fee /
                                                    100))
                                              )}
                                            </>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="submit-btn-2 pointer"
                              onClick={() => {
                                if (
                                  depositCryptoFormData?.transaction_hash?.trim()
                                    .length > 65
                                ) {
                                  if (depositCryptoFormData?.token > 0) {
                                    if (
                                      depositCryptoFormData.saving_balance == 0 ||
                                      depositCryptoFormData.saving_balance == 1 ||
                                      depositCryptoFormData.saving_balance == 2
                                    ) {
                                      if (
                                        depositCryptoFormData?.account_id.length >
                                        0
                                      ) {
                                        if (
                                          depositCryptoFormData.saving_balance ==
                                          1
                                        ) {
                                          if (
                                            depositCryptoFormData?.amount >=
                                            Number(
                                              calculateFullAmount(
                                                settings?.minimum_deposit_stake,
                                                settings?.split_percentage_stake
                                              )
                                            )
                                          ) {
                                            !error && submitCryptoHandle();
                                          } else {
                                            toast.error(
                                              `For this split deposit, you need to deposit a total amount of $${calculateFullAmount(
                                                settings?.minimum_deposit_stake,
                                                settings?.split_percentage_stake
                                              )} to reach the minimum deposit amount of $${settings?.minimum_deposit_stake
                                              } for Treasury Balance.`
                                            );
                                          }
                                        } else {
                                          !error && submitCryptoHandle();
                                        }
                                      } else {
                                        toast.error('Please select a wallet.');
                                      }
                                    } else {
                                      toast.error(
                                        'Please select a deposit type.'
                                      );
                                    }
                                  } else {
                                    toast.error(
                                      'Please select a cryptocurrency.'
                                    );
                                  }
                                } else {
                                  toast.error(
                                    'Invalid Tx Hash, it must be 66 characters.'
                                  );
                                }
                              }}
                            >
                              <div className="overlap-group-8">
                                <div className="text-wrapper-19">
                                  {modalDictionary['MODAL-title13']}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {/* Bank */}
                          <div className="right-2">
                            <div className="content-4">
                              <div
                                className="deposit-ck-editor-data"
                                dangerouslySetInnerHTML={{
                                  __html: isShowBankDetails
                                    ? settings?.bank_info
                                    : walletInfoDepositModal?.bank?.[0]?.address
                                }}
                              ></div>
                              <div className="deposit-ck-editor-data-switch">
                                <div
                                  className={
                                    isShowBankDetails
                                      ? 'deposit-ck-editor-data-switch-btn pointer'
                                      : 'deposit-ck-editor-data-switch-btn pointer active'
                                  }
                                  onClick={() => setIsShowBankDetails(false)}
                                >
                                  Bank Instructions
                                </div>
                                <div
                                  className={
                                    isShowBankDetails
                                      ? 'deposit-ck-editor-data-switch-btn pointer active'
                                      : 'deposit-ck-editor-data-switch-btn pointer'
                                  }
                                  onClick={() => setIsShowBankDetails(true)}
                                >
                                  Bank Details
                                </div>
                              </div>
                              <div className="result-info-2">
                                <div className="bg-2" />
                                <div className="payment-details-2">
                                  <div className="div-3">
                                    <div className="item">
                                      <div className="text-wrapper-8">
                                        {modalDictionary['MODAL-title7']}
                                      </div>
                                      <div className="text-wrapper-9">
                                        Deposit
                                      </div>
                                    </div>
                                    <div className="item">
                                      <div className="text-wrapper-8">
                                        {modalDictionary['MODAL-title8']}
                                      </div>
                                      <div className="text-wrapper-9">
                                        $
                                        {numberWithCommas(
                                          depositWireFormData.amount,
                                          0
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <img
                                    className="line-4"
                                    alt="Line"
                                    src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/line-6.svg"
                                  />
                                  <div className="div-3">
                                    <div className="item">
                                      <div className="title-2">
                                        <div className="text-wrapper-13">
                                          {modalDictionary['MODAL-title28']}
                                        </div>
                                        <Flex
                                          width="fit-content"
                                          y="center"
                                          x="end"
                                          gap={0.5}
                                        >
                                          <div
                                            className="tooltip-info tooltip-info-normal"
                                            style={{ right: 0, top: '10px' }}
                                          >
                                            <div
                                              className="tooltip-info-icon"
                                              style={{
                                                height: 26
                                              }}
                                            >
                                              <div className="info-wrapper">
                                                <img
                                                  className="img"
                                                  alt="Info"
                                                  src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/info-7.svg"
                                                />
                                              </div>
                                            </div>
                                            <div
                                              className="tooltip-info-text"
                                              style={{ top: '30px' }}
                                            >
                                              <span>
                                                {modalDictionary['MODAL-title23']}
                                              </span>
                                            </div>
                                          </div>
                                        </Flex>
                                      </div>
                                      <div className="text-wrapper-9">
                                        {depositCryptoFormData.saving_balance ==
                                          0 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                depositCryptoFormData.amount *
                                                ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100)
                                              )}
                                            </>
                                          )}
                                        {depositCryptoFormData.saving_balance ==
                                          1 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                depositCryptoFormData.amount *
                                                ((100 -
                                                  Number(
                                                    settings?.split_percentage_stake
                                                  )) /
                                                  100) *
                                                ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100)
                                              )}{' '}
                                              / $
                                              {numberWithCommas(
                                                depositCryptoFormData.amount *
                                                (Number(
                                                  settings?.split_percentage_stake
                                                ) /
                                                  100) *
                                                (settings?.stake_deposit_fee /
                                                  100)
                                              )}
                                            </>
                                          )}
                                        {depositCryptoFormData.saving_balance ==
                                          2 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                depositCryptoFormData.amount *
                                                (settings?.stake_deposit_fee /
                                                  100)
                                              )}
                                            </>
                                          )}
                                      </div>
                                    </div>
                                    <div className="item">
                                      <div className="text-wrapper-8">
                                        {modalDictionary['MODAL-title29']}
                                      </div>
                                      <div className="text-wrapper-9">
                                        {depositCryptoFormData.saving_balance ==
                                          0 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                Number(
                                                  depositCryptoFormData.amount
                                                ) -
                                                Number(
                                                  depositCryptoFormData.amount
                                                ) *
                                                ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100)
                                              )}
                                            </>
                                          )}

                                        {depositCryptoFormData.saving_balance ==
                                          1 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                Number(
                                                  depositCryptoFormData.amount *
                                                  ((100 -
                                                    Number(
                                                      settings?.split_percentage_stake
                                                    )) /
                                                    100)
                                                ) -
                                                Number(
                                                  depositCryptoFormData.amount
                                                ) *
                                                ((100 -
                                                  Number(
                                                    settings?.split_percentage_stake
                                                  )) /
                                                  100) *
                                                ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100)
                                              )}{' '}
                                              / $
                                              {numberWithCommas(
                                                Number(
                                                  depositCryptoFormData.amount *
                                                  (Number(
                                                    settings?.split_percentage_stake
                                                  ) /
                                                    100)
                                                ) -
                                                Number(
                                                  depositCryptoFormData.amount
                                                ) *
                                                (Number(
                                                  settings?.split_percentage_stake
                                                ) /
                                                  100) *
                                                (settings?.stake_deposit_fee /
                                                  100)
                                              )}
                                            </>
                                          )}

                                        {depositCryptoFormData.saving_balance ==
                                          2 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                Number(
                                                  depositCryptoFormData.amount
                                                ) -
                                                Number(
                                                  depositCryptoFormData.amount
                                                ) *
                                                (settings?.stake_deposit_fee /
                                                  100)
                                              )}
                                            </>
                                          )}
                                      </div>
                                    </div>
                                    <div className="item">
                                      <p className="text-wrapper-8 flex gap-x-1 items-center">
                                        {depositCryptoFormData.saving_balance ==
                                          0 && (
                                            <>{modalDictionary['MODAL-title9']}</>
                                          )}
                                        {depositCryptoFormData.saving_balance ==
                                          1 && (
                                            <>
                                              {modalDictionary['MODAL-title9-1']}
                                              <Flex
                                                width="fit-content"
                                                y="center"
                                                x="end"
                                                gap={0.5}
                                              >
                                                <div
                                                  className="tooltip-info tooltip-info-normal"
                                                  style={{ right: 0, top: '10px' }}
                                                >
                                                  <div
                                                    className="tooltip-info-icon"
                                                    style={{
                                                      height: 26
                                                    }}
                                                  >
                                                    <div className="info-wrapper">
                                                      <img
                                                        className="img"
                                                        alt="Info"
                                                        src={infoIcon}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="tooltip-info-text"
                                                    style={{ top: '30px' }}
                                                  >
                                                    <span>
                                                      {
                                                        modalDictionary[
                                                        'MODAL-title9-1-1'
                                                        ]
                                                      }
                                                    </span>
                                                  </div>
                                                </div>
                                              </Flex>
                                            </>
                                          )}
                                        {depositCryptoFormData.saving_balance ==
                                          2 && (
                                            <>{modalDictionary['MODAL-title9-2']}</>
                                          )}
                                      </p>
                                      <div className="text-wrapper-9">
                                        {depositCryptoFormData.saving_balance ==
                                          0 && (
                                            <>
                                              {numberWithCommas(
                                                Number(balancesNew?.main_balance) +
                                                Number(
                                                  balancesNew?.bos_interest
                                                ) +
                                                (Number(
                                                  depositCryptoFormData.amount
                                                ) -
                                                  Number(
                                                    depositCryptoFormData.amount
                                                  ) *
                                                  ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100))
                                              )}
                                            </>
                                          )}
                                        {depositCryptoFormData.saving_balance ==
                                          1 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                Number(balancesNew?.main_balance) +
                                                Number(
                                                  balancesNew?.bos_interest
                                                ) +
                                                (Number(
                                                  depositCryptoFormData.amount *
                                                  ((100 -
                                                    Number(
                                                      settings?.split_percentage_stake
                                                    )) /
                                                    100)
                                                ) -
                                                  depositCryptoFormData.amount *
                                                  ((100 -
                                                    Number(
                                                      settings?.split_percentage_stake
                                                    )) /
                                                    100) *
                                                  ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100))
                                              )}{' '}
                                              / $
                                              {numberWithCommas(
                                                Number(
                                                  balancesNew?.treasury_balance
                                                ) +
                                                (Number(
                                                  depositCryptoFormData.amount *
                                                  (Number(
                                                    settings?.split_percentage_stake
                                                  ) /
                                                    100)
                                                ) -
                                                  depositCryptoFormData.amount *
                                                  (Number(
                                                    settings?.split_percentage_stake
                                                  ) /
                                                    100) *
                                                  (settings?.stake_deposit_fee /
                                                    100))
                                              )}
                                            </>
                                          )}

                                        {depositCryptoFormData.saving_balance ==
                                          2 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                Number(
                                                  balancesNew?.treasury_balance
                                                ) +
                                                (Number(
                                                  depositCryptoFormData.amount
                                                ) -
                                                  Number(
                                                    depositCryptoFormData.amount
                                                  ) *
                                                  (settings?.stake_deposit_fee /
                                                    100))
                                              )}
                                            </>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="submit-btn-2 pointer"
                              onClick={() => {
                                if (
                                  allAccountsWire &&
                                  allAccountsWire?.data?.length === 0
                                ) {
                                  toast.error('You need to add a wallet first');
                                } else {
                                  if (depositWireFormData.bank_type) {
                                    if (
                                      depositCryptoFormData.saving_balance == 0 ||
                                      depositCryptoFormData.saving_balance == 1 ||
                                      depositCryptoFormData.saving_balance == 2
                                    ) {
                                      if (
                                        depositWireFormData?.account_id.length > 0
                                      ) {
                                        if (
                                          depositCryptoFormData.saving_balance ==
                                          1
                                        ) {
                                          if (
                                            depositWireFormData?.amount >=
                                            Number(
                                              calculateFullAmount(
                                                settings?.minimum_deposit_stake,
                                                settings?.split_percentage_stake
                                              )
                                            )
                                          ) {
                                            submitWireHandle();
                                          } else {
                                            toast.error(
                                              `For this split deposit, you need to deposit a total amount of $${calculateFullAmount(
                                                settings?.minimum_deposit_stake,
                                                settings?.split_percentage_stake
                                              )} to reach the minimum deposit amount of $${settings?.minimum_deposit_stake
                                              } for Treasury Balance.`
                                            );
                                          }
                                        } else {
                                          submitWireHandle();
                                        }
                                      } else {
                                        toast.error('Please select an account');
                                      }
                                    } else {
                                      toast.error(
                                        'Please select a deposit type.'
                                      );
                                    }
                                  } else {
                                    toast.error(
                                      'Please select the correct BOS Bank Name to continue.'
                                    );
                                  }
                                }
                              }}
                            >
                              <div className="overlap-group-8">
                                <div className="text-wrapper-19">
                                  {modalDictionary['MODAL-title13']}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}

                    {screenWidth >= 1037 &&
                      (isCrypto ? (
                        <>
                          {/* Crypto */}
                          <img
                            className="line-5"
                            alt="Line"
                            src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/line-2.svg"
                          />
                          <div className="right-3">
                            <div className="content-5">
                              <div className="wallet-info-2">
                                <div className="qr">
                                  {walletInfoDepositModal ? (
                                    <QRCodeSVG
                                      style={{
                                        background: 'white',
                                        padding: '1rem'
                                      }}
                                      value={
                                        walletInfoDepositModal?.crypto?.[0]
                                          ?.address
                                      }
                                      size={150}
                                      bgColor={'#ffffff'}
                                      fgColor={'#000000'}
                                      level={'L'}
                                      includeMargin={false}
                                    />
                                  ) : (
                                    <img
                                      className="mod-img"
                                      src="/images/QRCode.png"
                                      alt="QRCode"
                                    />
                                  )}
                                  <p className="note-2">
                                    {dictionary['MY-WALLET-title41']}
                                    &nbsp;{' '}
                                    <a
                                      className="bos-call"
                                      href="https://calendly.com/d/ckn7-b7d-24n/bos-deposit-call"
                                      target="_blank"
                                    >
                                      Book a call if you need assistance.
                                    </a>
                                  </p>
                                </div>
                                <img
                                  className="line-3"
                                  alt="Line"
                                  src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/line-3.svg"
                                />
                                <div className="wallet-3">
                                  <div
                                    className="wallet-address-2"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        walletInfoDepositModal?.crypto?.[0]
                                          ?.address
                                    }}
                                  ></div>
                                  <CopyToClipboard
                                    text={
                                      walletInfoDepositModal?.crypto?.[0]?.address
                                    }
                                    onCopy={() => {
                                      toast.success('Copied Successfully.');
                                    }}
                                  >
                                    <div className="copy-btn pointer">
                                      <div className="text">
                                        {modalDictionary['MODAL-title32']}
                                      </div>
                                    </div>
                                  </CopyToClipboard>
                                </div>
                              </div>
                              <div className="result-info-3">
                                <div className="bg-3" />
                                <div className="payment-details-3">
                                  <div className="div-3">
                                    <div className="item">
                                      <div className="text-wrapper-8">
                                        {modalDictionary['MODAL-title7']}
                                      </div>
                                      <div className="text-wrapper-9">
                                        Deposit
                                      </div>
                                    </div>
                                    <div className="item">
                                      <div className="text-wrapper-8">
                                        {modalDictionary['MODAL-title8']}
                                      </div>
                                      <div className="text-wrapper-9">
                                        $
                                        {numberWithCommas(
                                          depositCryptoFormData.amount
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <img
                                    className="line-6"
                                    alt="Line"
                                    src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/line-4.svg"
                                  />
                                  <div className="div-3">
                                    <div className="item">
                                      <div className="title-2">
                                        <div className="text-wrapper-13">
                                          {modalDictionary['MODAL-title28']}
                                        </div>
                                        <Flex
                                          width="fit-content"
                                          y="center"
                                          x="end"
                                          gap={0.5}
                                        >
                                          <div
                                            className="tooltip-info tooltip-info-normal"
                                            style={{ right: 0, top: '10px' }}
                                          >
                                            <div
                                              className="tooltip-info-icon"
                                              style={{
                                                height: 26
                                              }}
                                            >
                                              <div className="info-wrapper">
                                                <img
                                                  className="img"
                                                  alt="Info"
                                                  src={infoIcon}
                                                />
                                              </div>
                                            </div>
                                            <div
                                              className="tooltip-info-text"
                                              style={{ top: '30px' }}
                                            >
                                              <span>
                                                {modalDictionary['MODAL-title23']}
                                              </span>
                                            </div>
                                          </div>
                                        </Flex>
                                      </div>
                                      <div className="text-wrapper-9">
                                        {depositCryptoFormData.saving_balance ==
                                          0 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                depositCryptoFormData.amount *
                                                ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100)
                                              )}
                                            </>
                                          )}
                                        {depositCryptoFormData.saving_balance ==
                                          1 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                depositCryptoFormData.amount *
                                                ((100 -
                                                  Number(
                                                    settings?.split_percentage_stake
                                                  )) /
                                                  100) *
                                                ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100)
                                              )}{' '}
                                              / $
                                              {numberWithCommas(
                                                depositCryptoFormData.amount *
                                                (Number(
                                                  settings?.split_percentage_stake
                                                ) /
                                                  100) *
                                                (settings?.stake_deposit_fee /
                                                  100)
                                              )}
                                            </>
                                          )}
                                        {depositCryptoFormData.saving_balance ==
                                          2 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                depositCryptoFormData.amount *
                                                (settings?.stake_deposit_fee /
                                                  100)
                                              )}
                                            </>
                                          )}
                                      </div>
                                    </div>
                                    <div className="item">
                                      <div className="text-wrapper-8">
                                        {modalDictionary['MODAL-title29']}
                                      </div>
                                      <div className="text-wrapper-9">
                                        {depositCryptoFormData.saving_balance ==
                                          0 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                Number(
                                                  depositCryptoFormData.amount
                                                ) -
                                                Number(
                                                  depositCryptoFormData.amount
                                                ) *
                                                ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100)
                                              )}
                                            </>
                                          )}

                                        {depositCryptoFormData.saving_balance ==
                                          1 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                Number(
                                                  depositCryptoFormData.amount *
                                                  ((100 -
                                                    Number(
                                                      settings?.split_percentage_stake
                                                    )) /
                                                    100)
                                                ) -
                                                Number(
                                                  depositCryptoFormData.amount
                                                ) *
                                                ((100 -
                                                  Number(
                                                    settings?.split_percentage_stake
                                                  )) /
                                                  100) *
                                                ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100)
                                              )}{' '}
                                              / $
                                              {numberWithCommas(
                                                Number(
                                                  depositCryptoFormData.amount *
                                                  (Number(
                                                    settings?.split_percentage_stake
                                                  ) /
                                                    100)
                                                ) -
                                                Number(
                                                  depositCryptoFormData.amount
                                                ) *
                                                (Number(
                                                  settings?.split_percentage_stake
                                                ) /
                                                  100) *
                                                (settings?.stake_deposit_fee /
                                                  100)
                                              )}
                                            </>
                                          )}

                                        {depositCryptoFormData.saving_balance ==
                                          2 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                Number(
                                                  depositCryptoFormData.amount
                                                ) -
                                                Number(
                                                  depositCryptoFormData.amount
                                                ) *
                                                (settings?.stake_deposit_fee /
                                                  100)
                                              )}
                                            </>
                                          )}
                                      </div>
                                    </div>
                                    <div className="item">
                                      <p className="text-wrapper-8 flex items-center gap-x-1">
                                        {depositCryptoFormData.saving_balance ==
                                          0 && (
                                            <>{modalDictionary['MODAL-title9']}</>
                                          )}
                                        {depositCryptoFormData.saving_balance ==
                                          1 && (
                                            <>
                                              {modalDictionary['MODAL-title9-1']}
                                              <Flex
                                                width="fit-content"
                                                y="center"
                                                x="end"
                                                gap={0.5}
                                              >
                                                <div
                                                  className="tooltip-info tooltip-info-normal"
                                                  style={{ right: 0, top: '10px' }}
                                                >
                                                  <div
                                                    className="tooltip-info-icon"
                                                    style={{
                                                      height: 26
                                                    }}
                                                  >
                                                    <div className="info-wrapper">
                                                      <img
                                                        className="img"
                                                        alt="Info"
                                                        src={infoIcon}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="tooltip-info-text"
                                                    style={{ top: '30px' }}
                                                  >
                                                    <span>
                                                      {
                                                        modalDictionary[
                                                        'MODAL-title9-1-1'
                                                        ]
                                                      }
                                                    </span>
                                                  </div>
                                                </div>
                                              </Flex>
                                            </>
                                          )}
                                        {depositCryptoFormData.saving_balance ==
                                          2 && (
                                            <>{modalDictionary['MODAL-title9-2']}</>
                                          )}
                                      </p>
                                      <div className="text-wrapper-9">
                                        {depositCryptoFormData.saving_balance ==
                                          0 && (
                                            <>
                                              {numberWithCommas(
                                                Number(balancesNew?.main_balance) +
                                                Number(
                                                  balancesNew?.bos_interest
                                                ) +
                                                (Number(
                                                  depositCryptoFormData.amount
                                                ) -
                                                  Number(
                                                    depositCryptoFormData.amount
                                                  ) *
                                                  ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100))
                                              )}
                                            </>
                                          )}
                                        {depositCryptoFormData.saving_balance ==
                                          1 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                Number(balancesNew?.main_balance) +
                                                Number(
                                                  balancesNew?.bos_interest
                                                ) +
                                                (Number(
                                                  depositCryptoFormData.amount *
                                                  ((100 -
                                                    Number(
                                                      settings?.split_percentage_stake
                                                    )) /
                                                    100)
                                                ) -
                                                  depositCryptoFormData.amount *
                                                  ((100 -
                                                    Number(
                                                      settings?.split_percentage_stake
                                                    )) /
                                                    100) *
                                                  ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100))
                                              )}{' '}
                                              / $
                                              {numberWithCommas(
                                                Number(
                                                  balancesNew?.treasury_balance
                                                ) +
                                                (Number(
                                                  depositCryptoFormData.amount *
                                                  (Number(
                                                    settings?.split_percentage_stake
                                                  ) /
                                                    100)
                                                ) -
                                                  depositCryptoFormData.amount *
                                                  (Number(
                                                    settings?.split_percentage_stake
                                                  ) /
                                                    100) *
                                                  (settings?.stake_deposit_fee /
                                                    100))
                                              )}
                                            </>
                                          )}

                                        {depositCryptoFormData.saving_balance ==
                                          2 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                Number(
                                                  balancesNew?.treasury_balance
                                                ) +
                                                (Number(
                                                  depositCryptoFormData.amount
                                                ) -
                                                  Number(
                                                    depositCryptoFormData.amount
                                                  ) *
                                                  (settings?.stake_deposit_fee /
                                                    100))
                                              )}
                                            </>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="submit-btn-3 pointer"
                              onClick={() => {
                                if (
                                  depositCryptoFormData?.transaction_hash?.trim()
                                    .length > 65
                                ) {
                                  if (depositCryptoFormData?.token > 0) {
                                    if (
                                      depositCryptoFormData.saving_balance == 0 ||
                                      depositCryptoFormData.saving_balance == 1 ||
                                      depositCryptoFormData.saving_balance == 2
                                    ) {
                                      if (
                                        depositCryptoFormData?.account_id.length >
                                        0
                                      ) {
                                        if (
                                          depositCryptoFormData.saving_balance ==
                                          1
                                        ) {
                                          if (
                                            depositCryptoFormData?.amount >=
                                            Number(
                                              calculateFullAmount(
                                                settings?.minimum_deposit_stake,
                                                settings?.split_percentage_stake
                                              )
                                            )
                                          ) {
                                            submitCryptoHandle();
                                          } else {
                                            toast.error(
                                              `For this split deposit, you need to deposit a total amount of $${calculateFullAmount(
                                                settings?.minimum_deposit_stake,
                                                settings?.split_percentage_stake
                                              )} to reach the minimum deposit amount of $${settings?.minimum_deposit_stake
                                              } for Treasury Balance.`
                                            );
                                          }
                                        } else {
                                          submitCryptoHandle();
                                        }
                                      } else {
                                        toast.error('Please select a wallet.');
                                      }
                                    } else {
                                      toast.error(
                                        'Please select a deposit type.'
                                      );
                                    }
                                  } else {
                                    toast.error(
                                      'Please select a cryptocurrency.'
                                    );
                                  }
                                } else {
                                  toast.error(
                                    'Invalid Tx Hash, it must be 66 characters.'
                                  );
                                }
                              }}
                            >
                              <div className="overlap-group-9">
                                <div className="text-wrapper-20">
                                  {modalDictionary['MODAL-title13']}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {/* Bank */}
                          <img
                            className="line-5"
                            alt="Line"
                            src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/line-2.svg"
                          />
                          <div className="right-3">
                            <div className="content-5">
                              <div
                                className="deposit-ck-editor-data"
                                dangerouslySetInnerHTML={{
                                  __html: isShowBankDetails
                                    ? settings?.bank_info
                                    : walletInfoDepositModal?.bank?.[0]?.address
                                }}
                              ></div>
                              <div className="deposit-ck-editor-data-switch">
                                <div
                                  className={
                                    isShowBankDetails
                                      ? 'deposit-ck-editor-data-switch-btn pointer'
                                      : 'deposit-ck-editor-data-switch-btn pointer active'
                                  }
                                  onClick={() => setIsShowBankDetails(false)}
                                >
                                  Bank Instructions
                                </div>
                                <div
                                  className={
                                    isShowBankDetails
                                      ? 'deposit-ck-editor-data-switch-btn pointer active'
                                      : 'deposit-ck-editor-data-switch-btn pointer'
                                  }
                                  onClick={() => setIsShowBankDetails(true)}
                                >
                                  Bank Details
                                </div>
                              </div>
                              <div className="result-info-3">
                                <div className="bg-3" />
                                <div className="payment-details-3">
                                  <div className="div-3">
                                    <div className="item">
                                      <div className="text-wrapper-8">
                                        {modalDictionary['MODAL-title7']}
                                      </div>
                                      <div className="text-wrapper-9">
                                        Deposit
                                      </div>
                                    </div>
                                    <div className="item">
                                      <div className="text-wrapper-8">
                                        {modalDictionary['MODAL-title8']}
                                      </div>
                                      <div className="text-wrapper-9">
                                        $
                                        {numberWithCommas(
                                          depositWireFormData.amount
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <img
                                    className="line-6"
                                    alt="Line"
                                    src="https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d99e60a35d01b72ebade85/img/line-4.svg"
                                  />
                                  <div className="div-3">
                                    <div className="item">
                                      <div className="title-2">
                                        <div className="text-wrapper-13">
                                          {modalDictionary['MODAL-title28']}
                                        </div>
                                        <Flex
                                          width="fit-content"
                                          y="center"
                                          x="end"
                                          gap={0.5}
                                        >
                                          <div
                                            className="tooltip-info tooltip-info-normal"
                                            style={{ right: 0, top: '10px' }}
                                          >
                                            <div
                                              className="tooltip-info-icon"
                                              style={{
                                                height: 26
                                              }}
                                            >
                                              <div className="info-wrapper">
                                                <img
                                                  className="img"
                                                  alt="Info"
                                                  src={infoIcon}
                                                />
                                              </div>
                                            </div>
                                            <div
                                              className="tooltip-info-text"
                                              style={{ top: '30px' }}
                                            >
                                              <span>
                                                {modalDictionary['MODAL-title23']}
                                              </span>
                                            </div>
                                          </div>
                                        </Flex>
                                      </div>
                                      <div className="text-wrapper-9">
                                        {depositCryptoFormData.saving_balance ==
                                          0 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                depositCryptoFormData.amount *
                                                ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100)
                                              )}
                                            </>
                                          )}
                                        {depositCryptoFormData.saving_balance ==
                                          1 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                depositCryptoFormData.amount *
                                                ((100 -
                                                  Number(
                                                    settings?.split_percentage_stake
                                                  )) /
                                                  100) *
                                                ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100)
                                              )}{' '}
                                              / $
                                              {numberWithCommas(
                                                depositCryptoFormData.amount *
                                                (Number(
                                                  settings?.split_percentage_stake
                                                ) /
                                                  100) *
                                                (settings?.stake_deposit_fee /
                                                  100)
                                              )}
                                            </>
                                          )}
                                        {depositCryptoFormData.saving_balance ==
                                          2 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                depositCryptoFormData.amount *
                                                (settings?.stake_deposit_fee /
                                                  100)
                                              )}
                                            </>
                                          )}
                                      </div>
                                    </div>
                                    <div className="item">
                                      <div className="text-wrapper-8">
                                        {modalDictionary['MODAL-title29']}
                                      </div>
                                      <div className="text-wrapper-9">
                                        {depositCryptoFormData.saving_balance ==
                                          0 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                Number(
                                                  depositCryptoFormData.amount
                                                ) -
                                                Number(
                                                  depositCryptoFormData.amount
                                                ) *
                                                ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100)
                                              )}
                                            </>
                                          )}

                                        {depositCryptoFormData.saving_balance ==
                                          1 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                Number(
                                                  depositCryptoFormData.amount *
                                                  ((100 -
                                                    Number(
                                                      settings?.split_percentage_stake
                                                    )) /
                                                    100)
                                                ) -
                                                Number(
                                                  depositCryptoFormData.amount
                                                ) *
                                                ((100 -
                                                  Number(
                                                    settings?.split_percentage_stake
                                                  )) /
                                                  100) *
                                                ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100)
                                              )}{' '}
                                              / $
                                              {numberWithCommas(
                                                Number(
                                                  depositCryptoFormData.amount *
                                                  (Number(
                                                    settings?.split_percentage_stake
                                                  ) /
                                                    100)
                                                ) -
                                                Number(
                                                  depositCryptoFormData.amount
                                                ) *
                                                (Number(
                                                  settings?.split_percentage_stake
                                                ) /
                                                  100) *
                                                (settings?.stake_deposit_fee /
                                                  100)
                                              )}
                                            </>
                                          )}

                                        {depositCryptoFormData.saving_balance ==
                                          2 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                Number(
                                                  depositCryptoFormData.amount
                                                ) -
                                                Number(
                                                  depositCryptoFormData.amount
                                                ) *
                                                (settings?.stake_deposit_fee /
                                                  100)
                                              )}
                                            </>
                                          )}
                                      </div>
                                    </div>
                                    <div className="item">
                                      <p className="text-wrapper-8 flex gap-x-1 items-center">
                                        {depositCryptoFormData.saving_balance ==
                                          0 && (
                                            <>{modalDictionary['MODAL-title9']}</>
                                          )}
                                        {depositCryptoFormData.saving_balance ==
                                          1 && (
                                            <>
                                              {modalDictionary['MODAL-title9-1']}
                                              <Flex
                                                width="fit-content"
                                                y="center"
                                                x="end"
                                                gap={0.5}
                                              >
                                                <div
                                                  className="tooltip-info tooltip-info-normal"
                                                  style={{ right: 0, top: '10px' }}
                                                >
                                                  <div
                                                    className="tooltip-info-icon"
                                                    style={{
                                                      height: 26
                                                    }}
                                                  >
                                                    <div className="info-wrapper">
                                                      <img
                                                        className="img"
                                                        alt="Info"
                                                        src={infoIcon}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="tooltip-info-text"
                                                    style={{ top: '30px' }}
                                                  >
                                                    <span>
                                                      {
                                                        modalDictionary[
                                                        'MODAL-title9-1-1'
                                                        ]
                                                      }
                                                    </span>
                                                  </div>
                                                </div>
                                              </Flex>
                                            </>
                                          )}
                                        {depositCryptoFormData.saving_balance ==
                                          2 && (
                                            <>{modalDictionary['MODAL-title9-2']}</>
                                          )}
                                      </p>
                                      <div className="text-wrapper-9">
                                        {depositCryptoFormData.saving_balance ==
                                          0 && (
                                            <>
                                              {numberWithCommas(
                                                Number(balancesNew?.main_balance) +
                                                Number(
                                                  balancesNew?.bos_interest
                                                ) +
                                                (Number(
                                                  depositCryptoFormData.amount
                                                ) -
                                                  Number(
                                                    depositCryptoFormData.amount
                                                  ) *
                                                  ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100))
                                              )}
                                            </>
                                          )}
                                        {depositCryptoFormData.saving_balance ==
                                          1 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                Number(balancesNew?.main_balance) +
                                                Number(
                                                  balancesNew?.bos_interest
                                                ) +
                                                (Number(
                                                  depositCryptoFormData.amount *
                                                  ((100 -
                                                    Number(
                                                      settings?.split_percentage_stake
                                                    )) /
                                                    100)
                                                ) -
                                                  depositCryptoFormData.amount *
                                                  ((100 -
                                                    Number(
                                                      settings?.split_percentage_stake
                                                    )) /
                                                    100) *
                                                  ((depositCryptoFormData.saving_balance == 1 ? settings?.split_deposit_fee : settings?.deposit_fee) / 100))
                                              )}{' '}
                                              / $
                                              {numberWithCommas(
                                                Number(
                                                  balancesNew?.treasury_balance
                                                ) +
                                                (Number(
                                                  depositCryptoFormData.amount *
                                                  (Number(
                                                    settings?.split_percentage_stake
                                                  ) /
                                                    100)
                                                ) -
                                                  depositCryptoFormData.amount *
                                                  (Number(
                                                    settings?.split_percentage_stake
                                                  ) /
                                                    100) *
                                                  (settings?.stake_deposit_fee /
                                                    100))
                                              )}
                                            </>
                                          )}

                                        {depositCryptoFormData.saving_balance ==
                                          2 && (
                                            <>
                                              $
                                              {numberWithCommas(
                                                Number(
                                                  balancesNew?.treasury_balance
                                                ) +
                                                (Number(
                                                  depositCryptoFormData.amount
                                                ) -
                                                  Number(
                                                    depositCryptoFormData.amount
                                                  ) *
                                                  (settings?.stake_deposit_fee /
                                                    100))
                                              )}
                                            </>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="submit-btn-3 pointer"
                              onClick={() => {
                                if (
                                  allAccountsWire &&
                                  allAccountsWire?.data?.length === 0
                                ) {
                                  toast.error('You need to add a wallet first');
                                } else {
                                  if (depositWireFormData.bank_type) {
                                    if (
                                      depositCryptoFormData.saving_balance == 0 ||
                                      depositCryptoFormData.saving_balance == 1 ||
                                      depositCryptoFormData.saving_balance == 2
                                    ) {
                                      if (
                                        depositWireFormData?.account_id.length > 0
                                      ) {
                                        if (
                                          depositCryptoFormData.saving_balance ==
                                          1
                                        ) {
                                          if (
                                            depositWireFormData?.amount >=
                                            Number(
                                              calculateFullAmount(
                                                settings?.minimum_deposit_stake,
                                                settings?.split_percentage_stake
                                              )
                                            )
                                          ) {
                                            submitWireHandle();
                                          } else {
                                            toast.error(
                                              `For this split deposit, you need to deposit a total amount of $${calculateFullAmount(
                                                settings?.minimum_deposit_stake,
                                                settings?.split_percentage_stake
                                              )} to reach the minimum deposit amount of $${settings?.minimum_deposit_stake
                                              } for Treasury Balance.`
                                            );
                                          }
                                        } else {
                                          submitWireHandle();
                                        }
                                      } else {
                                        toast.error('Please select an account');
                                      }
                                    } else {
                                      toast.error(
                                        'Please select a deposit type.'
                                      );
                                    }
                                  } else {
                                    toast.error(
                                      'Please select the correct BOS Bank Name to continue.'
                                    );
                                  }
                                }
                              }}
                            >
                              <div className="overlap-group-9">
                                <div className="text-wrapper-20">
                                  {modalDictionary['MODAL-title13']}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                  </div>
                  <div
                    className="hr"
                    style={{
                      borderRadius: screenWidth >= 1037 ? '16px' : undefined,
                      width:
                        screenWidth >= 564 && screenWidth < 1037
                          ? '564px'
                          : screenWidth >= 1037
                            ? '1037px'
                            : undefined
                    }}
                  />
                </>
              )}

            <header
              className="header"
              style={{
                width:
                  screenWidth < 564
                    ? '373px'
                    : screenWidth >= 564 && screenWidth < 1037
                      ? '516px'
                      : screenWidth >= 1037
                        ? '993px'
                        : undefined
              }}
            >
              <div
                className="text-wrapper-21"
                style={{
                  marginRight: screenWidth < 564 ? '-21.18px' : undefined
                }}
              >
                Deposit
              </div>
              <div
                className="close-btn"
                onClick={() => {
                  //   setModalDeposit(false);
                  setModalAreYouSure(true);
                  setIsShowBankDetails(false);
                }}
                style={{
                  marginLeft: screenWidth < 564 ? '-59.48px' : undefined
                }}
              >
                <img
                  className="union"
                  alt="Union"
                  src={
                    (screenWidth >= 564 && screenWidth < 1037) ||
                      screenWidth < 564
                      ? 'https://anima-uploads.s3.amazonaws.com/projects/64b77eb848521524cc106dda/releases/64d7420f1ba4c21dede3e26a/img/union-1.svg'
                      : screenWidth >= 1037
                        ? closeBtn
                        : undefined
                  }
                />
              </div>
            </header>
            {screenWidth < 564 && <div className="hr-2" />}
          </div>
        </div>
      )}

      {modalAreYouSure && (
        <Flex x="center">
          <div className="mod">
            <div className="mod-border">
              <div className="mod-box">
                <p className="mod-box-p">{dictionary['MY-WALLET-title126']}</p>
                <button
                  className="mod-box-btn"
                  onClick={() => setModalAreYouSure(false)}
                >
                  X
                </button>
              </div>
              <div style={{ marginRight: 10, marginLeft: 10 }}></div>
              <div className="mod-inside">
                <div className="mod-inside-body">
                  <p>
                    <b>{dictionary['MY-WALLET-title127']}</b>
                    {dictionary['MY-WALLET-title128']}
                  </p>
                </div>
                <Flex gap={0.8} x="center">
                  <button
                    className="mod-inside-body-btn pointer"
                    onClick={() => {
                      setModalDeposit(false);
                      setModalAreYouSure(false);
                    }}
                  >
                    {dictionary['MY-WALLET-title130']}
                  </button>

                  <button
                    className="mod-inside-body-btn pointer"
                    onClick={() => {
                      setModalAreYouSure(false);
                    }}
                  >
                    {dictionary['MY-WALLET-title129']}
                  </button>
                </Flex>
              </div>
            </div>
          </div>
        </Flex>
      )}

      {modalDeposit && depositSuccessData && (
        <SuccessModal
          requestedAmount={depositSuccessData?.requestedAmount}
          txID={depositSuccessData?.txID}
          method={ModalMethods.Deposit}
          time={depositSuccessData?.time}
          fee={depositSuccessData?.fee}
          finalAmount={depositSuccessData?.finalAmount}
          treasuryFee={depositSuccessData?.treasuryFee}
          finalTreasuryAmount={depositSuccessData?.finalTreasuryAmount}
          saving_balance={depositSuccessData?.saving_balance}
          closeState={setModalDeposit}
        />
      )}
    </>
  );
};