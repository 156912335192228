import { useContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import CKEditorContent from '../../components/CKEditorContent';
import Faqs from '../../components/Faqs';
import Loader from '../../components/Loader';
import MyMapView from '../../components/MyMapView';
import MyTreasure from '../../components/MyTreasure';
import Notifications from '../../components/Notifications';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import Sumsub from '../../components/Sumsub';
import VerifyEmailView from '../../components/VerifyEmail';
import {
  AccountSetup,
  ApplicationForm, BOSCrew, FinancialAccount, IntroductionVideo, Island,
  IslandsList,
  Login,
  MyTreasures, MyWallet, Profile
} from '../../components/index';
import config from '../../config';
import AccountContext, { AccountContextProvider } from '../../context/private/accountContext';
import { BosContextProvider } from '../../context/private/bosContext';
import { CrewContextProvider } from '../../context/private/crewContext';
import { IslandsContextProvider } from '../../context/private/islandsContext';
import { MapContextProvider } from '../../context/private/mapContext';
import PrivateContext, {
  PrivateContextProvider
} from '../../context/private/privateContext';
import { ProfileContextProvider } from '../../context/private/profileContext';
import { TreasuryContextProvider } from '../../context/private/treasuryContext';
import { WalletContextProvider } from '../../context/private/walletContext';
import { WizardContextProvider } from '../../context/private/wizardContext';
import wizardService from '../../services/wizardService';
import FaqsList from '../../components/FaqsList';
import FaqsPage from '../../components/FaqsPage';
import FaqsQuestions from '../../components/FaqsQuestions';
import publicService from '../../services/publicService';
import SophisticateView from '../../components/Sophisticate';
import Logs from '../../components/Logs';
import DiamondBalance from '../../components/DiamondBalance';
import ExpirationView from '../../components/ExpirationView';

const WizardRoutes = (): JSX.Element => {
  const { flowState, isFlowOpen, loadUserSteps, sophisticateStatus } = useContext(PrivateContext);
  const { userInfo } = useContext(AccountContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const getToken = window.localStorage.getItem('token');

    const urlParams = new URLSearchParams(window.location.search);
    const tokenParam = urlParams.get('token');

    if (!getToken && !tokenParam) {
      navigate('/login');
    } else {
      loadUserSteps();
    }
  }, [location])


  if (sophisticateStatus !== null && flowState && flowState?.step !== -1) {
    // console.log("sophisticateStatus", sophisticateStatus)
    switch (flowState?.step) {
      case 0:
        return (
          <Routes>
            <Route path="/" element={
              isFlowOpen ? (
                <WizardContextProvider>
                  <VerifyEmailView />
                </WizardContextProvider>
              ) : (
                <Loader />
              )
            } />
          </Routes>
        );
      case 1:
        return (
          <Routes>
            <Route path="/" element={
              isFlowOpen ? (
                <WizardContextProvider>
                  <VerifyEmailView />
                </WizardContextProvider>
              ) : (
                <Loader />
              )
            } />
          </Routes>
        );
      case 2:
        return (
          <Routes>
            <Route path="/" element={
              isFlowOpen ? (
                <WizardContextProvider>
                  <VerifyEmailView />
                </WizardContextProvider>
              ) : (
                <Loader />
              )
            } />
          </Routes>
        );
      case 3:
        return (
          <Routes>
            <Route path="/" element={
              isFlowOpen ? (
                <WizardContextProvider>
                  <VerifyEmailView />
                </WizardContextProvider>
              ) : (
                <Loader />
              )
            } />
          </Routes>
        );
      case 4:
        return (
          <Routes>
            <Route path="/" element={
              isFlowOpen ? (
                <WizardContextProvider>
                  <IntroductionVideo />
                </WizardContextProvider>
              ) : (
                <Loader />
              )
            } />
          </Routes>
        );
      case 5:
        return (
          <Routes>
            <Route path="/" element={
              isFlowOpen ? (
                <WizardContextProvider>
                  <IntroductionVideo />
                </WizardContextProvider>
              ) : (
                <Loader />
              )
            } />
          </Routes>
        );
      case 6:
        return (
          <Routes>
            <Route path="/" element={
              isFlowOpen ? (
                <WizardContextProvider>
                  <IntroductionVideo />
                </WizardContextProvider>
              ) : (
                <Loader />
              )
            } />
          </Routes>
        );
      case 7:
        return (
          <Routes>
            <Route path="/" element={
              isFlowOpen ? (
                <WizardContextProvider>
                  <IntroductionVideo />
                </WizardContextProvider>
              ) : (
                <Loader />
              )
            } />
          </Routes>
        );
      case 8:
        return (
          <Routes>
            <Route path="/" element={
              isFlowOpen ? (
                <WizardContextProvider>
                  <IntroductionVideo />
                </WizardContextProvider>
              ) : (
                <Loader />
              )
            } />
          </Routes>
        );

      case 20:
        return (
          <Routes>
            <Route path="/" element={
              isFlowOpen ? (
                <WizardContextProvider>
                  <Sumsub />
                </WizardContextProvider>
              ) : (
                <Loader />
              )
            } />
          </Routes>
        );

      case 9:
        return (
          <Routes>
            <Route path="/" element={
              isFlowOpen ? (
                <WizardContextProvider>
                  <ApplicationForm />
                </WizardContextProvider>
              ) : (
                <Loader />
              )
            } />
          </Routes>
        );
      case 10:
        return (
          <Routes>
            <Route path="/" element={
              isFlowOpen ? (
                <WizardContextProvider>
                  <ApplicationForm />
                </WizardContextProvider>
              ) : (
                <Loader />
              )
            } />
          </Routes>
        );
      case 11:
        return (
          <Routes>
            <Route path="/" element={
              isFlowOpen ? (
                <WizardContextProvider>
                  <ApplicationForm />
                </WizardContextProvider>
              ) : (
                <Loader />
              )
            } />
          </Routes>
        );
      case 12:
        return (
          <Routes>
            <Route path="/" element={
              isFlowOpen ? (
                <WizardContextProvider>
                  <ApplicationForm />
                </WizardContextProvider>
              ) : (
                <Loader />
              )
            } />
          </Routes>
        );
      case 13:
        return (
          <Routes>
            <Route path="/" element={
              isFlowOpen ? (
                <WizardContextProvider>
                  <ApplicationForm />
                </WizardContextProvider>
              ) : (
                <Loader />
              )
            } />
          </Routes>
        );
      case 14:
        return (
          <Routes>
            <Route path="/" element={
              isFlowOpen ? (
                <WizardContextProvider>
                  <AccountSetup />
                </WizardContextProvider>
              ) : (
                <Loader />
              )
            } />
          </Routes>
        );
      case 15:
        return (
          <Routes>
            <Route path="/" element={
              isFlowOpen ? (
                <WizardContextProvider>
                  <AccountSetup />
                </WizardContextProvider>
              ) : (
                <Loader />
              )
            } />
          </Routes>
        );
      case 16:
        return (
          <Routes>
            <Route path="/" element={
              isFlowOpen ? (
                <WizardContextProvider>
                  <AccountSetup />
                </WizardContextProvider>
              ) : (
                <Loader />
              )
            } />
          </Routes>
        );
      case 17:

        if (userInfo && userInfo?.membershipStatus === 2) {
          return (
            <Routes>
              <Route path="/" element={isFlowOpen ? (
                <WizardContextProvider>
                  <ExpirationView />
                </WizardContextProvider>
              ) : (
                <Loader />
              )} />
            </Routes>
          );
        } else {

          if (sophisticateStatus?.isActive === 1 && (sophisticateStatus?.status === 0)) {
            return (
              <Routes>
                <Route path="/" element={
                  isFlowOpen ? (
                    <WizardContextProvider>
                      <SophisticateView />
                    </WizardContextProvider>
                  ) : (
                    <Loader />
                  )
                } />
              </Routes>
            )
          } else {
            return (


              <Routes>
                <Route path="/" element={isFlowOpen ? <MyMapView /> : <Loader />} />
              </Routes>
            );
          }
        }

      default:
        return (
          window.location.pathname.toLowerCase() !== "/login" ? <Loader /> : <></>
        );
    }

  } else {
    return (
      window.location.pathname.toLowerCase() !== "/login" ? <Loader /> : <></>
    )
  }
  return <></>
};


const MainRoutes = (): JSX.Element => {
  const { flowState, isFlowOpen, loadUserSteps, sophisticateStatus } = useContext(PrivateContext);
  const { userInfo } = useContext(AccountContext);

  // console.log("sophisticateStatus xx", sophisticateStatus);
  return (
    <Routes>

      <Route path="/islands" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <IslandsList /> : <SophisticateView />} />
      <Route path="/island-page" element={
        <IslandsContextProvider>
          <Island />
        </IslandsContextProvider>
      } />
      <Route path="/my-treasures" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <MyTreasures /> : <SophisticateView />} />
      <Route path="/membership" element={
        userInfo?.membershipStatus === 1 || userInfo?.membershipStatus === 2 ? (
          <WizardContextProvider>
            <MapContextProvider>
              <ExpirationView />
            </MapContextProvider>
          </WizardContextProvider>
        ) : <></>
      } />
      <Route path="/bos-crew" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <BOSCrew /> : <SophisticateView />} />
      <Route path="/faq" element={<Faqs />} />
      <Route path="/faq-system" element={<FaqsList />} />
      <Route path="/faq-system/questions" element={<FaqsQuestions />} />
      <Route path="/faq-system/questions/question" element={<FaqsPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/my-wallet" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <MyWallet /> : <SophisticateView />} />
      <Route path="/treasury-balance" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <DiamondBalance /> : <SophisticateView />} />
      <Route path="/profile" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <Profile /> : <SophisticateView />} />
      <Route path="/my-treasure/island" element={
        <IslandsContextProvider>
          <TreasuryContextProvider>
            <MyTreasure />
          </TreasuryContextProvider>
        </IslandsContextProvider>
      } />
      <Route path="/financial-account" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <FinancialAccount /> : <SophisticateView />} />
      <Route path="/notifications" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <Notifications /> : <SophisticateView />} />
      <Route path="/logs" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <Logs /> : <SophisticateView />} />

      <Route path="/sophisticate-register" element={(sophisticateStatus?.status === 1) ? <Profile /> : sophisticateStatus?.isActive === 0 ? <SophisticateView /> : <SophisticateView />} />
      <Route path="/socials" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={1} /> : <SophisticateView />} />
      <Route path="/academy" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={2} /> : <SophisticateView />} />
      <Route path="/service" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={18} /> : <SophisticateView />} />
      <Route path="/imbl" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={22} /> : <SophisticateView />} />
      <Route path="/offshore-banking" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={29} /> : <SophisticateView />} />
      <Route path="/caye" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={30} /> : <SophisticateView />} />
      <Route path="/chase" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={31} /> : <SophisticateView />} />
      <Route path="/recommendations" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={23} /> : <SophisticateView />} />
      <Route path="/strategy-session" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={24} /> : <SophisticateView />} />
      <Route path="/nevis-2025" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={25} /> : <SophisticateView />} />
      <Route path="/nevis-2025-registration" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={26} /> : <SophisticateView />} />
      <Route path="/bos-stories" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={27} /> : <SophisticateView />} />
      <Route path="/offshore-companies" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={11} /> : <SophisticateView />} />
      <Route path="/debitcard" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={12} /> : <SophisticateView />} />
      <Route path="/academy" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={2} /> : <SophisticateView />} />
      <Route path="/lifestyle" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={3} /> : <SophisticateView />} />
      <Route path="/events" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={4} /> : <SophisticateView />} />
      <Route path="/shop" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={5} /> : <SophisticateView />} />
      <Route path="/charity" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={6} /> : <SophisticateView />} />
      <Route path="/helpdesk" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={7} /> : <SophisticateView />} />
      <Route path="/dashboard-faqs" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={13} /> : <SophisticateView />} />
      <Route path="/tutorials" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={8} /> : <SophisticateView />} />
      <Route path="/dashboard-tutorials" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={16} /> : <SophisticateView />} />
      <Route path="/crypto-tutorials" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={17} /> : <SophisticateView />} />
      <Route path="/updates" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={9} /> : <SophisticateView />} />
      <Route path="/aboutus" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={10} /> : <SophisticateView />} />
      <Route path="/bos-faqs" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={14} /> : <SophisticateView />} />
      <Route path="/bos-video-archive" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={15} /> : <SophisticateView />} />
      <Route path="/meetings" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={19} /> : <SophisticateView />} />
      <Route path="/qa" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={20} /> : <SophisticateView />} />
      <Route path="/webinars" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={21} /> : <SophisticateView />} />

      <Route path="/wealth-blueprint" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={32} /> : <SophisticateView />} />
      <Route path="/treasury-strategy" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={33} /> : <SophisticateView />} />

      <Route path="/my-strategy" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={34} /> : <SophisticateView />} />
      <Route path="/bos-beacons" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={35} /> : <SophisticateView />} />

      <Route path="/withdraw" element={sophisticateStatus?.isActive === 0 || (sophisticateStatus?.status === 1 || sophisticateStatus?.status === 2 || sophisticateStatus?.status === 3) ? <CKEditorContent type={28} /> : <SophisticateView />} />


      {/* {sophisticateStatus === 3 || sophisticateStatus === 2 || sophisticateStatus === 1 || sophisticateStatus === 0 && <Route path="/*" element={<SophisticateView />} />} */}

    </Routes>
  )
}

const PrivateRoutes = (): any => {
  const navigate = useNavigate();
  const location = useLocation();


  const [isFlowOpen, setFlowOpen] = useState(false)
  const [flowState, setFlowState] = useState<{
    step: number, menu: number, status: number
  }>({
    step: 0,
    menu: 0,
    status: 0,
  })

  const loadUserSteps = async () => {
    const response = await wizardService.getStep()
    response && setFlowState(response)
    response && setFlowOpen(true)
  }

  const [hasAccess, setHasAccess] = useState<boolean>(false);
  const [currentPath, setCurrentAccess] = useState<string>(location.pathname);

  const getToken = (): string | null =>
    localStorage.getItem(config.service.tokenKey);

  useEffect(() => {
    const currentToken = getToken();
    currentPath !== location.pathname && setCurrentAccess(location.pathname);

    if (currentToken) {
      setHasAccess(true);
    } else {
      location.pathname.toLowerCase() !== '/login' &&
        location.pathname.toLowerCase() !== '/register' &&
        location.pathname.toLowerCase() !== '/forget-password' &&
        location.pathname.toLowerCase() !== '/new-password' &&
        navigate('/login');
      setHasAccess(false);
    }
  });

  useEffect(() => {
    const currentToken = getToken();

    location.pathname.toLowerCase() !== '/login' &&
      location.pathname.toLowerCase() !== '/register' &&
      location.pathname.toLowerCase() !== '/forget-password' &&
      location.pathname.toLowerCase() !== '/new-password' &&
      loadUserSteps();

  }, [currentPath]);

  function startsWith(str: string, startsWith: string): boolean {
    return str.substr(0, startsWith.length) === startsWith;
  }


  if (hasAccess && flowState && flowState.step === -1) return <Loader />
  if (hasAccess && isFlowOpen && flowState && flowState.step !== -1) {
    return (
      <PrivateContextProvider defaultLoaded={isFlowOpen} defaultFlow={flowState}>
        <ProfileContextProvider>
          <AccountContextProvider>
            <CrewContextProvider>
              <WalletContextProvider>
                <MapContextProvider>
                  <WizardRoutes />
                </MapContextProvider>
                <IslandsContextProvider>
                  <TreasuryContextProvider>
                    <BosContextProvider>

                      <MainRoutes />
                    </BosContextProvider>
                  </TreasuryContextProvider>
                </IslandsContextProvider>
              </WalletContextProvider>
            </CrewContextProvider>
          </AccountContextProvider>
        </ProfileContextProvider>
      </PrivateContextProvider>
    );
  } else {
    return hasAccess ? <></> : <></>;
  }
};

export default PrivateRoutes;
